import {
  createAssociatedTokenAccountIdempotentInstruction,
  createTransferCheckedInstruction,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import { PublicKey, SystemProgram } from "@solana/web3.js";
import toast from "react-hot-toast";
import { tx } from "./tx";

export async function airdrop(drops, token, publicKey, signAllTransactions, toastId) {
  let feeWallet = process.env.REACT_APP_FEE_WALLET;
  let feeAmount = process.env.REACT_APP_AD_FEE_AMOUNT;

  let mint = new PublicKey(token.id);

  let source_ata = await getAssociatedTokenAddress(
    mint, // mint
    publicKey // owner
  );

  let instructions = [];
  let aird = [];
  let result = [];

  instructions.push(
    SystemProgram.transfer({
      fromPubkey: publicKey,
      toPubkey: new PublicKey(feeWallet),
      lamports: feeAmount * Math.pow(10, 9),
    })
  );

  for (const res of drops) {
    try {
      // calculate ATA
      let ata = await getAssociatedTokenAddress(
        mint, // mint
        new PublicKey(res.address) // owner
      );
      instructions.push(
        createAssociatedTokenAccountIdempotentInstruction(
          publicKey, // payer
          ata, // ata
          new PublicKey(res.address), // owner
          mint // mint
        )
      );
      instructions.push(
        createTransferCheckedInstruction(
          source_ata, // from (should be a token account)
          mint, // mint
          ata, // to (should be a token account)
          publicKey, // from's owner
          res.amount * Math.pow(10, token.token_info.decimals), // amount, if your deciamls is 8, send 10^8 for 1 token
          token.token_info.decimals // decimals
        )
      );
      aird.push({ address: res.address, amount: res.amount });
    } catch {
      result.push({ batch: res, tx: null, confirmed: false });
      continue;
    }
  }

  let batchSize = 18;

  const txRes = await tx(
    publicKey,
    signAllTransactions,
    toastId,
    aird,
    instructions,
    result,
    batchSize
  );

  if (!txRes) return;

  const succ = txRes.reduce(
    (count, entry) => count + (entry.confirmed === true ? entry.batch.length : 0),
    0
  );

  if (succ.length > 0) {
    toast.success(`Airdrop completed. ${succ} / ${drops.length} airdrops sent.`, {
      id: toastId,
      duration: 60000,
    });
  }

  let fres = txRes.filter((res) => !res.confirmed);

  return fres;
}
