import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Menu,
  Drawer,
  IconButton,
} from "@mui/material";
import { Link, NavLink, useLocation } from "react-router-dom";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import MenuIcon from "@mui/icons-material/Menu";
import logop from "../assets/s5no.png";
import ParaglidingIcon from "@mui/icons-material/Paragliding";
import LinkIcon from "@mui/icons-material/Link";
import { useState } from "react";

export const Nav = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabs, setTabs] = useState([]);

  let tabOptions = [
    {
      type: "swap",
      link: "swap",
      icon: <SwapHorizIcon fontSize="small" />,
      text: "Swap Tokens",
      desc: "Swap any two tokens",
    },
    {
      type: "swap",
      link: "pumpFarm",
      icon: <LinkIcon fontSize="small" />,
      text: "Pump Farm",
      desc: "Swap or farm pump.fun tokens",
    },
    {
      type: "swap",
      link: "bulkswap",
      icon: <BlurOnIcon fontSize="small" />,
      text: "Bulk Swap",
      desc: "Swap many tokens at once in to Solana",
    },
    {
      type: "tools",
      link: "burn",
      icon: <LocalFireDepartmentIcon fontSize="small" />,
      text: "Burn Tokens",
      desc: "Burn unwanted / liquidity tokens",
    },
    {
      type: "tools",
      link: "empty",
      icon: <DoDisturbOnIcon fontSize="small" />,
      text: "Redeem Rent",
      desc: "Close empty token accounts and redeem Solana",
    },
    {
      type: "creator",
      link: "airdrop",
      icon: <ParaglidingIcon fontSize="small" />,
      text: "Mass Airdrop",
      desc: "Airdrop your token to the masses",
    },
    {
      type: "creator",
      link: "managelp",
      icon: <ManageAccountsIcon fontSize="small" />,
      text: "Remove Liquidity",
      desc: "Quickly remove liquidity from Raydium LPs",
    },
  ];

  let navHeaders = [
    {
      type: "swap",
      name: "Swap",
    },
    {
      type: "tools",
      name: "Tools",
    },
    {
      type: "creator",
      name: "Creator",
    },
  ];

  function handleHover(event, type) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
    setTabs(tabOptions.filter((tab) => tab.type === type));
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ paddingBottom: 50 }}>
      <AppBar position="static" sx={{ backgroundColor: "#0d1013", boxShadow: "none" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography
            variant="h6"
            component="div"
            justifyContent={"start"}
            onMouseOver={handleClose}
            style={{ zIndex: 1301 }}
          >
            <Link to={"/"} style={{ textDecoration: "none", color: "white" }}>
              <img src={logop} alt="_." height={50} width={50} />
            </Link>
          </Typography>
          <Box
            gap={4}
            textAlign={"center"}
            justifyContent={"center"}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Box width={2} py={2} sx={{ zIndex: 1301 }} onMouseOver={handleClose}></Box>
            {navHeaders.map((nav) => (
              <Box
                key={nav.name}
                py={2}
                style={{
                  textDecoration: "none",
                  fontWeight: 600,
                  cursor: "pointer",
                  alignContent: "center",
                  zIndex: 1301,
                }}
                onMouseOver={(e) => handleHover(e, nav.type)}
              >
                {nav.name}
              </Box>
            ))}
            <Box width={2} py={2} sx={{ zIndex: 1301 }} onMouseOver={handleClose}></Box>
          </Box>
          <Box onMouseOver={handleClose} style={{ zIndex: 1301 }} display={"flex"}>
            <WalletMultiButton />
            <Box>
              <MobileMenu tabs={tabOptions} />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            background: "#0d101395",
            backgroundImage: "linear-gradient(165deg, #555f6930 3.89%, #555f6900 66.42%)",
            backdropFilter: "blur(30px)",
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
            py: 1,
          },
        }}
      >
        {tabs.map((tab) => (
          <NavLink
            to={`/${tab.link}`}
            className={({ isActive }) => (isActive ? "activeNav" : "inactiveNav")}
            key={tab.link}
            onClick={handleClose}
          >
            <Box
              p={2}
              px={4}
              pr={6}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#00000020")} // Change background color on hover
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff00")} // Revert back to original background color on hover out
            >
              <Box display={"flex"} alignContent={"center"} gap={1} fontWeight={600} pb={1}>
                {tab.icon}
                {tab.text}
              </Box>
              <Box fontSize={12} fontWeight={400} color={"text.secondary"}>
                {tab.desc}
              </Box>
            </Box>
          </NavLink>
        ))}
      </Menu>
    </div>
  );
};

export const Tabs = () => {
  const location = useLocation();

  let tabs = [];

  if (location.pathname === "/swap" || location.pathname === "/nuke") {
    tabs = [
      { link: "swap", icon: <SwapHorizIcon fontSize="small" />, text: "swap" },
      { link: "nuke", icon: <BlurOnIcon fontSize="small" />, text: "bulk swap" },
    ];
  }
  if (
    location.pathname === "/burn" ||
    location.pathname === "/manage" ||
    location.pathname === "/empty" ||
    location.pathname === "/airdrop"
  ) {
    tabs = [
      { link: "manage", icon: <ManageAccountsIcon fontSize="small" />, text: "liquidity" },
      { link: "burn", icon: <LocalFireDepartmentIcon fontSize="small" />, text: "burn" },
      { link: "empty", icon: <DoDisturbOnIcon fontSize="small" />, text: "redeem" },
      { link: "airdrop", icon: <ParaglidingIcon fontSize="small" />, text: "airdrop" },
    ];
  }

  return (
    <Box
      bgcolor="#455a6490"
      sx={{ backdropFilter: "blur(10px)", boxShadow: "0px 4px 8px 1px #00000080" }}
    >
      <Container maxWidth="lg">
        <Box display={"flex"} textAlign={"center"} justifyContent={"center"} mb={3}>
          {tabs.map((tab) => (
            <NavLink
              to={`/${tab.link}`}
              className={({ isActive }) => (isActive ? "activeNav" : "inactiveNav")}
              key={tab.link}
            >
              <Box
                display={"flex"}
                textAlign={"center"}
                justifyContent={"center"}
                alignContent={"center"}
                gap={1}
                p={1}
              >
                {tab.icon}
                {tab.text}
              </Box>
            </NavLink>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

const MobileMenu = ({ tabs }) => {
  const [state, setState] = useState(false);

  const toggleDrawer = () => {
    setState(!state);
  };
  const closeDrawer = () => {
    setState(false);
  };

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
      <IconButton size="large" onClick={toggleDrawer} color="inherit" style={{ zIndex: 5000 }}>
        <MenuIcon />
      </IconButton>
      <Drawer
        hideBackdrop
        anchor="top"
        open={state}
        onClick={closeDrawer}
        PaperProps={{
          sx: {
            width: "100%",
            height: "100%",
            background: "#0d101395",
            backdropFilter: "blur(20px)",
          },
        }}
      >
        <Container>
          <Box pt={8}>
            <Box>
              {tabs.map((tab) => (
                <NavLink
                  to={`/${tab.link}`}
                  className={({ isActive }) => (isActive ? "activeNav" : "inactiveNav")}
                  key={tab.link}
                  onClick={closeDrawer}
                >
                  <Box
                    display={"flex"}
                    alignContent={"center"}
                    justifyContent={"center"}
                    gap={1}
                    fontWeight={600}
                    p={2}
                    sx={{ textTransform: "uppercase" }}
                  >
                    <Box color={"text.secondary"}>{tab.icon}</Box>
                    <Box>{tab.text}</Box>
                  </Box>
                </NavLink>
              ))}
            </Box>
          </Box>
        </Container>
      </Drawer>
    </Box>
  );
};
