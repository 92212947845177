/* global BigInt */
import React from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { rpc } from "../config";
import { useEffect, useState } from "react";
import { burn } from "../functions/burn";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Container,
  Typography,
  TextField,
  Box,
  Checkbox,
  Button,
} from "@mui/material"; // Import Material-UI components
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import toast from "react-hot-toast";

export const Burn = () => {
  const { publicKey, signAllTransactions } = useWallet();
  useEffect(() => {
    setTokens([]);
    if (publicKey) {
      getAssetsByOwner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  const [tokens, setTokens] = useState([]);
  const getAssetsByOwner = async () => {
    const response = await fetch(rpc, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: "my-id",
        method: "getAssetsByOwner",
        params: {
          ownerAddress: publicKey?.toString(),
          //tokenType: "fungible",
          page: 1, // Starts at 1
          limit: 1000,
          displayOptions: {
            showFungible: true, //return both fungible and non-fungible tokens
          },
        },
      }),
    });
    const { result } = await response.json();
    if (!result) return;
    const toks = result?.items.filter(
      (obj) => obj.interface === "FungibleToken" || obj.interface === "FungibleAsset"
    );
    setTokens(toks);
  };

  const [selectedTokens, setSelectedTokens] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const handleTokenSelect = (tokenId, maxAmount) => (event) => {
    const isChecked = event.target.checked;
    setSelectedTokens((prevSelected) => ({
      ...prevSelected,
      [tokenId]: isChecked ? maxAmount : 0,
    }));
  };

  const handleBurn = async () => {
    // Filter selected tokens
    const selected = Object.entries(selectedTokens).filter(([_, amount]) => amount > 0);
    let sel = [];
    for (const [tokenId, amount] of selected) {
      const token = tokens.find((t) => t.id === tokenId);
      if (token) {
        sel.push({
          mint: token.id,
          decimals: token.token_info.decimals,
          ata: token.token_info.associated_token_address,
          token_program: token.token_info.token_program,
          amount: BigInt(amount),
        });
      }
    }
    if (sel.length > 0) {
      const toastId = toast.loading("Processing...");
      let res = await burn(sel, publicKey, signAllTransactions, toastId);
      if (res) getAssetsByOwner();
    }
  };

  const handleSelectAll = () => {
    const newSelectedTokens = {};
    tokens.forEach((token) => {
      newSelectedTokens[token.id] = selectAll ? 0 : token.token_info.balance;
    });
    setSelectedTokens(newSelectedTokens);
    setSelectAll(!selectAll);
  };

  return (
    <Container sx={{ p: 1, display: "flex", justifyContent: "center" }}>
      <Box
        maxWidth="sm"
        width={"100%"}
        sx={{
          bgcolor: "bg.po",
          backdropFilter: "blur(10px)",
          borderRadius: 2,
        }}
      >
        <List dense>
          <ListItem key="bheader" sx={{ py: 1, pb: 2 }}>
            <ListItemAvatar>
              <Box display={"flex"} alignContent={"center"}>
                <Box display={"flex"} alignSelf={"center"} fontSize={16}>
                  <LocalFireDepartmentIcon fontSize="inherit" sx={{ pr: 1 }} color="error" />
                </Box>
                <Box fontSize={20} fontWeight={600} color={"error.main"}>
                  Burn
                </Box>
              </Box>
            </ListItemAvatar>
            <ListItemText primary="" />
            <Box
              color={"text.secondary"}
              sx={{ display: "flex", gap: "8px", alignItems: "center" }}
            >
              <ListItemText primary="select all" />
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
                color="primary"
                size="small"
              />
            </Box>
          </ListItem>
          <Container
            sx={{
              bgcolor: "bg.so",
              maxHeight: 500,
              overflow: "auto",
            }}
          >
            {tokens.map((token) => (
              <ListItem
                key={token.id}
                disableGutters
                sx={{
                  borderBottom: 0.5,
                  borderColor: "bg.bord",
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{ width: 30, height: 30 }}
                    alt={token.content.metadata.symbol}
                    src={token.content.links.image}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    token.content.metadata.symbol ? (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                        href={`https://solscan.io/account/${token.id}`}
                      >
                        {token.content.metadata.symbol}
                      </a>
                    ) : (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                        href={`https://solscan.io/account/${token.id}`}
                      >
                        {token.id.substring(0, 4)}..{token.id.substring(token.id.length - 4)}
                      </a>
                    )
                  }
                  secondary={
                    <Typography
                      variant="caption"
                      color={"text.secondary"}
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <AccountBalanceWallet fontSize="xsmall" />
                      {parseFloat(
                        (
                          token.token_info.balance / Math.pow(10, token.token_info.decimals)
                        ).toFixed(2)
                      )}
                    </Typography>
                  }
                />
                <Box display="flex">
                  <TextField
                    type="number"
                    size="small"
                    sx={{ width: 100 }}
                    value={selectedTokens[token.id] / Math.pow(10, token.token_info.decimals) || ""}
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: token.token_info.balance / Math.pow(10, token.token_info.decimals),
                      },
                    }}
                    onChange={(e) =>
                      setSelectedTokens({
                        ...selectedTokens,
                        [token.id]: e.target.value * Math.pow(10, token.token_info.decimals),
                      })
                    }
                  />
                  <Checkbox
                    checked={selectedTokens[token.id] > 0}
                    onChange={handleTokenSelect(token.id, token.token_info.balance)}
                    color="primary"
                    size="small"
                  />
                </Box>
              </ListItem>
            ))}
            {tokens.length === 0 && (
              <ListItem
                key="zero"
                disableGutters
                color="white"
                sx={{ borderBottom: "solid 1px", borderColor: "background.paper" }}
              >
                <ListItemText color="text.primary" primary="Nothing to burn" />
              </ListItem>
            )}
          </Container>
          <ListItem key="footer" sx={{ py: 3, width: "100%" }}>
            <Button
              disabled={selectedTokens.length < 1}
              variant="contained"
              color="green"
              fullWidth
              onClick={handleBurn}
              sx={{ py: 2, borderRadius: 2, fontWeight: 600, color: "black" }}
            >
              BURN
            </Button>
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};
