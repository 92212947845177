import {
  TransactionInstruction,
  TransactionMessage,
  VersionedTransaction,
  PublicKey,
  AddressLookupTableAccount,
} from "@solana/web3.js";
import fetch from "cross-fetch";
import { connection } from "../config";
import toast from "react-hot-toast";
import { confirmTx } from "./confirm";

export async function multiSwap(
  tokens,
  outputMint,
  slippage,
  publicKey,
  signAllTransactions,
  toastId
) {
  try {
    let instructions = [];
    let result = [];
    slippage = slippage * 100;

    for (const token of tokens) {
      // Swapping SOL to USDC with input 0.1 SOL and 0.5% slippage
      const quoteResponse = await (
        await fetch(
          `https://quote-api.jup.ag/v6/quote?inputMint=${token.id}&outputMint=${outputMint}&amount=${token.balance}&slippageBps=${slippage}`
        )
      ).json();
      //console.log({ quoteResponse });

      if (quoteResponse.error) {
        result.push({ tx: null, confirmed: false });
        continue;
      }

      const instruction = await (
        await fetch("https://quote-api.jup.ag/v6/swap-instructions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // quoteResponse from /quote api
            quoteResponse,
            userPublicKey: publicKey.toBase58(),
            //feeAccount,
          }),
        })
      ).json();

      if (instruction.error) {
        result.push({ tx: null, confirmed: false });
        continue;
      }

      const {
        setupInstructions, // Setup missing ATA for the users
        swapInstruction: swapInstructionPayload, // The actual swap instruction.
        cleanupInstruction, // Unwrap the SOL if `wrapAndUnwrapSol = true`.
        addressLookupTableAddresses, // The lookup table addresses that you can use if you are using versioned transaction.
      } = instruction;

      const deserializeInstruction = (instruction) => {
        return new TransactionInstruction({
          programId: new PublicKey(instruction.programId),
          keys: instruction.accounts.map((key) => ({
            pubkey: new PublicKey(key.pubkey),
            isSigner: key.isSigner,
            isWritable: key.isWritable,
          })),
          data: Buffer.from(instruction.data, "base64"),
        });
      };

      const getAddressLookupTableAccounts = async (keys) => {
        const addressLookupTableAccountInfos = await connection.getMultipleAccountsInfo(
          keys.map((key) => new PublicKey(key))
        );

        return addressLookupTableAccountInfos.reduce((acc, accountInfo, index) => {
          const addressLookupTableAddress = keys[index];
          if (accountInfo) {
            const addressLookupTableAccount = new AddressLookupTableAccount({
              key: new PublicKey(addressLookupTableAddress),
              state: AddressLookupTableAccount.deserialize(accountInfo.data),
            });
            acc.push(addressLookupTableAccount);
          }

          return acc;
        }, []);
      };

      let addressLookupTableAccounts = await getAddressLookupTableAccounts(
        addressLookupTableAddresses
      );

      let inst = deserializeInstruction(swapInstructionPayload);

      let clean = deserializeInstruction(cleanupInstruction);
      let setup = setupInstructions.map(deserializeInstruction);

      instructions.push({
        setup: setup,
        inst: inst,
        clean: clean,
        add: addressLookupTableAccounts,
      });
    }

    if (instructions.length < 1) {
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 10000,
      });
      return false;
    }

    let latestBlockhash;

    try {
      latestBlockhash = await connection.getLatestBlockhash("confirmed");
    } catch (error) {
      console.log(error);
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 10000,
      });
      return false;
    }

    let transactions = [];
    let batchSize = 1;

    for (let i = 0; i < instructions.length; i += batchSize) {
      const messageV0 = new TransactionMessage({
        payerKey: publicKey,
        recentBlockhash: latestBlockhash.blockhash,
        instructions: [...instructions[i].setup, instructions[i].inst, instructions[i].clean],
      }).compileToV0Message(instructions[i].add);
      const transaction = new VersionedTransaction(messageV0);
      transactions.push(transaction);
    }

    let signedTxs;

    try {
      signedTxs = await signAllTransactions(transactions, connection);
    } catch {
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 10000,
      });
      return false;
    }

    toast.loading(`Processing ${transactions.length} transaction(s)..`, {
      id: toastId,
    });

    let txId = [];

    for (const si of signedTxs) {
      const sitx = si.serialize();
      try {
        let tx = await connection.sendRawTransaction(sitx);
        txId.push(tx);
        console.log(tx);
      } catch {}
    }

    if (txId.length < 1) {
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 10000,
      });
      return false;
    }

    for (const [i, tx] of txId.entries()) {
      const res = await confirmTx(tx);
      result.push({ tx: tx, confirmed: res });
    }

    if (!result) {
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 10000,
      });
      return false;
    }

    const succ = result.reduce((count, entry) => count + (entry.confirmed ? 1 : 0), 0);

    if (succ > 0) {
      toast.success(`Swap completed.`, {
        id: toastId,
        duration: 60000,
      });
      return true;
    } else {
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 60000,
      });
      return false;
    }
  } catch (error) {
    toast.error(`Transaction failed..`, {
      id: toastId,
      duration: 10000,
    });
    return false;
  }
}
