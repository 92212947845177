import React from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { rpc } from "../config";
import { useEffect, useState } from "react";
import { airdrop } from "../functions/airdrop";
import toast from "react-hot-toast";
import {
  List,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Container,
  Typography,
  ListItemButton,
  TextField,
  Box,
  Button,
  Modal,
  IconButton,
  Checkbox,
} from "@mui/material"; // Import Material-UI components
import CloseIcon from "@mui/icons-material/Close";
import ParaglidingIcon from "@mui/icons-material/Paragliding";

export const Airdrop = () => {
  const { publicKey, signAllTransactions } = useWallet();

  const AIRDROP_FEE = 0.1;

  useEffect(() => {
    setTokens([]);
    if (publicKey) {
      getAssetsByOwner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  const [tokens, setTokens] = useState([]);
  const getAssetsByOwner = async () => {
    const response = await fetch(rpc, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: "my-id",
        method: "getAssetsByOwner",
        params: {
          ownerAddress: publicKey?.toString(),
          //tokenType: "fungible",
          page: 1, // Starts at 1
          limit: 1000,
          displayOptions: {
            showFungible: true, //return both fungible and non-fungible tokens
          },
        },
      }),
    });
    const { result } = await response.json();
    if (!result) return;
    const toks = result?.items.filter(
      (obj) => obj.interface === "FungibleToken" || obj.interface === "FungibleAsset"
    );
    setTokens(toks);
  };

  const [input, setInput] = useState("");
  const [amount, setAmount] = useState("");
  const [fixed, setFixed] = useState(false);
  const [inputJ, setInputJ] = useState([]);
  const [token, setToken] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleConvert = async () => {
    if (inputJ.length < 1) return;
    let ad = inputJ;
    if (fixed && amount) {
      // Replace all amounts with the fixed amount
      ad = inputJ.map((entry) => ({
        ...entry,
        amount: amount,
      }));
    }
    const toastId = toast.loading("Processing...");
    const aird = await airdrop(ad, token, publicKey, signAllTransactions, toastId);
    if (!aird) return;
    const combinedBatch = aird.reduce((acc, entry) => {
      return acc.concat(entry.batch);
    }, []);
    // Convert combined batch to a comma-separated string with each entry on a new line
    const combinedString = combinedBatch
      .map((entry) => `${entry.address},${entry.amount}`)
      .join("\n");
    setInput(combinedString);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);
    const lines = value.split("\n");
    const result = lines
      .map((line) => {
        if (line.trim() === "") return undefined;
        const [address, amount] = line.split(",").map((item) => item.trim());
        return { address, amount };
      })
      .filter((entry) => entry !== undefined);

    setInputJ(result);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);
  };

  const handleOpenModal = async () => {
    setOpenModal(true);
  };

  const handleCloseModal = async () => {
    setOpenModal(false);
  };

  const handleOutputSelect = (item) => {
    setToken(item);
    setOpenModal(false);
  };

  return (
    <Container sx={{ p: 1, display: "flex", justifyContent: "center" }}>
      <Box
        maxWidth="sm"
        width={"100%"}
        sx={{
          bgcolor: "bg.po",
          backdropFilter: "blur(10px)",
          borderRadius: 2,
        }}
      >
        <Box gap={2} alignItems={"center"} alignContent={"center"}>
          <Box borderRadius={2}>
            <Box display={"flex"} alignContent={"center"} py={3} px={2}>
              <Box display={"flex"} alignSelf={"center"} fontSize={16}>
                <ParaglidingIcon fontSize="inherit" sx={{ pr: 1 }} color="primary" />
              </Box>
              <Box fontSize={20} fontWeight={600} color={"primary.main"}>
                Airdrop
              </Box>
            </Box>

            <Box
              bgcolor={"bg.so"}
              p={2}
              py={3}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              alignContent={"center"}
            >
              {token ? (
                <Box
                  display={"flex"}
                  onClick={handleOpenModal}
                  gap={1}
                  p={1}
                  alignItems={"center"}
                  alignContent={"center"}
                  sx={{ cursor: "pointer" }}
                >
                  <Avatar
                    alt={token.content.metadata.symbol}
                    src={token.content.links.image}
                    sx={{ width: 18, height: 18 }}
                  />
                  <Typography display={"flex"}>{token.content.metadata.symbol}</Typography>
                </Box>
              ) : (
                <Button color="green" onClick={handleOpenModal} sx={{ cursor: "pointer" }}>
                  Token
                </Button>
              )}
              <Box>
                <Checkbox
                  checked={fixed}
                  onChange={() => setFixed(!fixed)}
                  color="green"
                  size="small"
                />
                <TextField
                  disabled={!fixed}
                  placeholder="Fixed Amount"
                  variant="outlined"
                  value={amount}
                  onChange={handleAmountChange}
                  sx={{ bgcolor: "bg.so" }}
                  color="green"
                  size="small"
                />
              </Box>
            </Box>
          </Box>
          <Box p={2}>
            <TextField
              disabled={!token}
              placeholder="one address per line, comma separated (address, amount)"
              variant="outlined"
              fullWidth
              multiline
              rows={8}
              value={input}
              color="green"
              onChange={handleInputChange}
              sx={{ bgcolor: "bg.so" }}
            />
            <Box display={"flex"} flexDirection={"column"} textAlign={"right"} gap={1} py={2}>
              <Box display={"flex"} gap={1} justifyContent={"right"}>
                <Box color="text.secondary">Balance </Box>
                {token ? (
                  <>
                    {token.token_info.balance / Math.pow(10, token.token_info.decimals)} $
                    {token.content.metadata.symbol}
                  </>
                ) : (
                  0
                )}
              </Box>
              <Box display={"flex"} gap={1} justifyContent={"right"}>
                <Box color="text.secondary">Amount</Box>
                {token ? (
                  <>
                    {fixed ? (
                      <>
                        {amount * inputJ.length} ${token.content.metadata.symbol}
                      </>
                    ) : (
                      <>
                        {inputJ.reduce((sum, item) => sum + (Number(item.amount) || 0), 0)} $
                        {token.content.metadata.symbol}
                      </>
                    )}
                  </>
                ) : (
                  0
                )}
              </Box>
              <Box display={"flex"} gap={1} justifyContent={"right"}>
                <Box color="text.secondary">Remaining</Box>
                {token ? (
                  <>
                    {fixed ? (
                      <>
                        {token.token_info.balance / Math.pow(10, token.token_info.decimals) -
                          amount * inputJ.length}{" "}
                        ${token.content.metadata.symbol}
                      </>
                    ) : (
                      <>
                        {token.token_info.balance / Math.pow(10, token.token_info.decimals) -
                          inputJ.reduce((sum, item) => sum + (Number(item.amount) || 0), 0)}{" "}
                        ${token.content.metadata.symbol}
                      </>
                    )}
                  </>
                ) : (
                  0
                )}
              </Box>
              <Box display={"flex"} gap={1} justifyContent={"right"}>
                <Box color="text.secondary">Transactions</Box>
                {inputJ.length}
              </Box>
              <Box display={"flex"} gap={1} justifyContent={"right"}>
                <Box color="text.secondary">Estimated Cost</Box>
                {(inputJ.length * 0.002 + AIRDROP_FEE).toFixed(3)} SOL
              </Box>
            </Box>
            <Box textAlign={"right"} py={1}>
              <Button
                disabled={!token || inputJ.length === 0}
                variant="contained"
                color="green"
                fullWidth
                onClick={handleConvert}
                sx={{ py: 2, borderRadius: 2, fontWeight: 600, color: "black" }}
              >
                airdrop
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="select-token"
        aria-describedby="select-token"
      >
        <Box
          borderRadius={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            maxWidth: "95%",
            bgcolor: "bg.p",
            boxShadow: "0px 0px 5px 2px #000000",
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            justifyContent={"space-between"}
            p={2}
          >
            <Box>Select Token</Box>
            <Box>
              <IconButton onClick={handleCloseModal} size="small" color="green">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <List dense sx={{ maxHeight: "60vh", overflowY: "auto", bgcolor: "bg.so" }}>
            {tokens.map((result) => (
              <ListItemButton key={result.id} onClick={() => handleOutputSelect(result)}>
                <ListItemAvatar>
                  <Avatar src={result.content.links.image} sx={{ width: "24px", height: "24px" }} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body2" fontWeight={600}>
                      {result.content.metadata.symbol}
                    </Typography>
                  }
                  secondary={
                    <Typography component="span" variant="caption" color="text.secondary">
                      {result.id.substring(0, 4)}...
                      {result.id.substring(result.id.length - 4)}
                    </Typography>
                  }
                />
                <ListItemText
                  sx={{ textAlign: "end" }}
                  primary={
                    <Typography variant="body2">
                      {result?.token_info.balance
                        ? parseFloat(
                            (
                              result.token_info.balance / Math.pow(10, result.token_info.decimals)
                            ).toFixed(4)
                          )
                        : ""}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Modal>
    </Container>
  );
};
