import { pumpBuy } from "../functions/pumpFun/pumpBuy";
import { pumpSell } from "../functions/pumpFun/pumpSell";
import { pumpFarm } from "../functions/pumpFun/pumpFarm";
import { useWallet } from "@solana/wallet-adapter-react";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { searchAssets } from "../functions/searchAssets";
import {
  Container,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  Button,
  Typography,
  Modal,
  List,
  ListItemText,
  InputBase,
  IconButton,
  Box,
} from "@mui/material";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";

export const PumpFarm = () => {
  const { publicKey, signAllTransactions } = useWallet();

  const [assets, setAssets] = useState(0);

  const outputSymbol = "SOL";
  const outputIcon =
    "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png";

  const [searchInput, setSearchInput] = useState("");
  const [outputBalance, setOutputBalance] = useState(0);
  const [coinData, setCoinData] = useState({});
  const [notPump, setNotPump] = useState(false);
  const [amount, setAmount] = useState("");
  const [tokenAmount, setTokenAmount] = useState("");
  const [balance, setBalance] = useState(null);
  const [slippage, setSlippage] = useState(5);
  const [priorityFee, setPriorityFee] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (publicKey) {
      getAssets();
    }
  }, [publicKey]);

  async function getAssets() {
    searchAssets(publicKey)
      .then((res) => {
        setAssets(res);
        setOutputBalance(res?.nativeBalance.lamports / Math.pow(10, 9));
        if (coinData) {
          let hold = assets?.items?.filter((resi) => resi.id === coinData.mint);
          hold?.length > 0
            ? setBalance(hold[0].token_info.balance / Math.pow(10, hold[0].token_info.decimals))
            : setBalance(0);
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    const pattern = /^[a-zA-Z0-9]{41,}$/;
    if (pattern.test(searchInput)) {
      getCoin();
    }
  }, [searchInput]);

  async function getCoin() {
    let coinRes = await fetch(`https://frontend-api.pump.fun/coins/${searchInput}`);
    if (coinRes.error || !coinRes.ok) {
      setNotPump(true);
    } else {
      setNotPump(false);
    }
    let coinD = await coinRes.json();
    setCoinData(coinD);
    let hold = assets.items.filter((resi) => resi.id === searchInput);
    hold.length > 0
      ? setBalance(hold[0].token_info.balance / Math.pow(10, hold[0].token_info.decimals))
      : setBalance(0);
  }

  const handleChange = (e) => {
    setAmount(e.target.value);
    if (coinData?.market_cap) {
      let price = coinData.total_supply / Math.pow(10, 6) / coinData.market_cap;
      let amn = e.target.value * price;
      setTokenAmount(amn);
    }
  };

  const handleTokenChange = (e) => {
    setTokenAmount(e.target.value);
    if (coinData?.market_cap) {
      let price = coinData.market_cap / (coinData.total_supply / Math.pow(10, 6));
      let amn = e.target.value * price;
      setAmount(amn);
    }
  };

  const handleSlippageChange = (e) => {
    setSlippage(e.target.value);
  };

  const handleSearchUpdate = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSolMax = (amount) => {
    setAmount(amount);
    if (coinData?.market_cap) {
      let price = coinData.total_supply / Math.pow(10, 6) / coinData.market_cap;
      let amn = amount * price;
      setTokenAmount(amn);
    }
  };

  const handleTokenMax = (percent) => {
    setTokenAmount(balance * percent);
    if (coinData?.market_cap) {
      let price = coinData.market_cap / (coinData.total_supply / Math.pow(10, 6));
      let amn = balance * percent * price;
      setAmount(amn);
    }
  };

  const handleOpenModal = async () => {
    setOpenModal(true);
  };

  const handleCloseModal = async () => {
    setOpenModal(false);
  };

  const handleOutputSelect = (item) => {
    setSearchInput(item.id);
    setOpenModal(false);
  };

  const refreshRate = async () => {
    await getCoin();
    if (tokenAmount === balance) {
      handleTokenMax(1);
    } else {
      handleSolMax(amount);
    }
  };

  const buyClick = async () => {
    const toastId = toast.loading("Processing...");
    await pumpBuy(
      searchInput,
      amount,
      slippage,
      publicKey,
      signAllTransactions,
      toastId,
      priorityFee
    );
    getAssets();
  };

  const sellClick = async () => {
    const toastId = toast.loading("Processing...");
    await pumpSell(
      searchInput,
      tokenAmount,
      slippage,
      publicKey,
      signAllTransactions,
      toastId,
      priorityFee
    );
    getAssets();
  };

  const farmClick = async () => {
    const toastId = toast.loading("Processing...");
    await pumpFarm(
      searchInput,
      amount,
      slippage,
      publicKey,
      signAllTransactions,
      toastId,
      priorityFee
    );
    getAssets();
  };

  return (
    <Container sx={{ p: 1, display: "flex", justifyContent: "center" }}>
      <Box
        maxWidth="sm"
        width={"100%"}
        sx={{
          bgcolor: "bg.po",
          backdropFilter: "blur(10px)",
          borderRadius: 2,
        }}
      >
        <Box borderRadius={2} py={1}>
          <Box sx={{ display: "flex", p: 1, pt: 2 }} justifyContent={"space-between"}>
            <Box display={"flex"} alignContent={"center"} pl={1}>
              <Box display={"flex"} alignSelf={"center"} fontSize={16}>
                <GroupWorkIcon fontSize="inherit" sx={{ pr: 1 }} color="error" />
              </Box>
              <Box fontSize={20} fontWeight={600} color={"error.main"}>
                Pump Token
              </Box>
            </Box>
            <Box color={"secondary.main"} onClick={refreshRate} sx={{ cursor: "pointer" }}>
              <RefreshIcon />
            </Box>
          </Box>
          <Box display={"flex"} bgcolor={"bg.s"} p={1}>
            <Button color="green" onClick={handleOpenModal} sx={{ cursor: "pointer" }}>
              Tokens
            </Button>
            <InputBase
              placeholder="Search CA"
              fullWidth
              value={searchInput}
              onChange={handleSearchUpdate}
              sx={{ p: 1, borderRadius: 2 }}
              inputProps={{ style: { textAlign: "left" } }}
            />
          </Box>
          {notPump && (
            <Box bgcolor={"bg.so"} p={1} color={"error.main"} textAlign={"end"}>
              Not a valid pump.fun token
            </Box>
          )}
          {coinData.name && (
            <Box py={1}>
              <Box display={"flex"} justifyContent={"space-between"} p={1}>
                <Box display={"flex"} gap={1} fontSize={9}>
                  <Box
                    className="hoverPercent"
                    px={1}
                    py={0.5}
                    onClick={() => handleTokenMax(0.25)}
                  >
                    25%
                  </Box>
                  <Box className="hoverPercent" px={1} py={0.5} onClick={() => handleTokenMax(0.5)}>
                    50%
                  </Box>
                  <Box className="hoverPercent" px={1} py={0.5} onClick={() => handleTokenMax(1)}>
                    100%
                  </Box>
                </Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="right"
                  sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                  onClick={() => handleTokenMax(1)}
                >
                  <AccountBalanceWalletIcon fontSize="xsmall" sx={{ pr: 0.5 }} />
                  {parseFloat(balance?.toFixed(4))} {coinData?.symbol}
                </Typography>
              </Box>
              <Box display={"flex"} bgcolor={"bg.so"} p={1} alignItems={"center"}>
                <Box display={"flex"} gap={1} p={1} alignItems={"center"} alignContent={"center"}>
                  <Avatar
                    alt={coinData?.name}
                    src={coinData?.image_uri}
                    sx={{ width: 20, height: 20 }}
                  />
                  <Box fontWeight={600}>
                    <a
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                      href={`https://pump.fun/${coinData?.mint}`}
                    >
                      {coinData?.symbol}
                    </a>
                  </Box>
                </Box>
                <InputBase
                  placeholder="0.00"
                  fullWidth
                  value={tokenAmount}
                  onChange={(e) => handleTokenChange(e)}
                  sx={{ p: 1, borderRadius: 2 }}
                  inputProps={{ style: { textAlign: "right" } }}
                />
              </Box>
            </Box>
          )}

          <Box textAlign={"center"} p={1}>
            <IconButton aria-label="swap">
              <SwapVertIcon />
            </IconButton>
          </Box>

          <Box py={1}>
            <Box display={"flex"} justifyContent={"space-between"} p={1}>
              <Box display={"flex"} alignContent={"center"}>
                <Box display={"flex"} gap={1} fontSize={9}>
                  <Box className="hoverPercent" px={1} py={0.5} onClick={() => handleSolMax(0.05)}>
                    0.05 SOL
                  </Box>
                  <Box className="hoverPercent" px={1} py={0.5} onClick={() => handleSolMax(0.5)}>
                    0.5 SOL
                  </Box>
                  <Box className="hoverPercent" px={1} py={0.5} onClick={() => handleSolMax(1)}>
                    1 SOL
                  </Box>
                </Box>
              </Box>
              <Typography
                variant="body2"
                color="text.secondary"
                align="left"
                sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                onClick={() => handleSolMax(outputBalance)}
              >
                <AccountBalanceWalletIcon fontSize="xsmall" sx={{ pr: 0.5 }} />
                {parseFloat(outputBalance.toFixed(4))} {outputSymbol}
              </Typography>
            </Box>
            <Box display={"flex"} bgcolor={"bg.so"} p={1}>
              <Box
                display={"flex"}
                gap={1}
                p={1}
                alignItems={"center"}
                alignContent={"center"}
                sx={{ cursor: "pointer" }}
              >
                <Avatar alt={outputSymbol} src={outputIcon} sx={{ width: 20, height: 20 }} />
                <Typography fontWeight={600} display={"flex"}>
                  {outputSymbol}
                </Typography>
              </Box>
              <InputBase
                placeholder="0.00"
                fullWidth
                value={amount}
                onChange={(e) => handleChange(e)}
                sx={{ p: 1, borderRadius: 2 }}
                inputProps={{ style: { textAlign: "right" } }}
              />
            </Box>
          </Box>

          <Box
            px={2}
            justifyContent={"right"}
            textAlign={"right"}
            display={"flex"}
            alignItems={"center"}
            gap={0.5}
            color={"text.secondary"}
          >
            <Box fontSize={14}>SLIPPAGE</Box>
            <InputBase
              size="small"
              value={slippage}
              onChange={handleSlippageChange}
              sx={{ width: 20, fontSize: 14, mt: 0.5, color: "text.secondary" }}
              inputProps={{ style: { textAlign: "right" } }}
            />
            <Box fontSize={14}>%</Box>
          </Box>
          <Box display={"flex"} gap={2} p={2} pt={4}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={buyClick}
              fullWidth
              sx={{ py: 2, borderRadius: 2, fontWeight: 600, color: "black" }}
            >
              Buy
            </Button>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={sellClick}
              fullWidth
              sx={{ py: 2, borderRadius: 2, fontWeight: 600, color: "black" }}
            >
              Sell
            </Button>
            <Button
              size="large"
              variant="contained"
              color="green"
              onClick={farmClick}
              fullWidth
              sx={{ py: 2, borderRadius: 2, fontWeight: 600, color: "black" }}
            >
              Farm
            </Button>
          </Box>
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="select-token"
        aria-describedby="select-token"
      >
        <Box
          borderRadius={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            maxWidth: "95%",
            bgcolor: "bg.p",
            boxShadow: "0px 0px 5px 2px #000000",
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            justifyContent={"space-between"}
            p={2}
          >
            <Box>Select Token</Box>
            <Box>
              <IconButton onClick={handleCloseModal} size="small" color="green">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <List dense sx={{ maxHeight: "60vh", overflowY: "auto", bgcolor: "bg.so" }}>
            {assets?.items?.map((result) => (
              <ListItemButton key={result.id} onClick={() => handleOutputSelect(result)}>
                <ListItemAvatar>
                  <Avatar src={result.content.links.image} sx={{ width: "24px", height: "24px" }} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body2" fontWeight={600}>
                      {result.content.metadata.symbol}
                    </Typography>
                  }
                  secondary={
                    <Typography component="span" variant="caption" color="text.secondary">
                      {result.id.substring(0, 4)}...
                      {result.id.substring(result.id.length - 4)}
                    </Typography>
                  }
                />
                <ListItemText
                  sx={{ textAlign: "end" }}
                  primary={
                    <Typography variant="body2">
                      {result?.token_info.balance
                        ? parseFloat(
                            (
                              result.token_info.balance / Math.pow(10, result.token_info.decimals)
                            ).toFixed(4)
                          )
                        : ""}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Modal>
    </Container>
  );
};
