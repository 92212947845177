import React from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { connection } from "../config";
import { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Checkbox,
  Box,
  Button,
  Container,
} from "@mui/material"; // Import Material-UI components
import { TOKEN_PROGRAM_ID, createCloseAccountInstruction } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import { tx } from "../functions/tx";
import toast from "react-hot-toast";

export const Empty = () => {
  const { publicKey, signAllTransactions } = useWallet();
  useEffect(() => {
    setTokens([]);
    if (publicKey) {
      getBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  const [tokens, setTokens] = useState([]);
  const getBalance = async () => {
    let response;
    try {
      response = await connection.getParsedTokenAccountsByOwner(publicKey, {
        programId: TOKEN_PROGRAM_ID,
      });
    } catch {}
    if (!response) return;
    const toks = response.value.filter(
      (obj) => obj.account.data.parsed.info.tokenAmount.amount === "0"
    );
    setTokens(toks);
  };

  const [selectedTokens, setSelectedTokens] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // Function to handle checkbox change for individual tokens
  const handleTokenCheckboxChange = (tokenId) => (event) => {
    setSelectedTokens((prevSelectedTokens) => ({
      ...prevSelectedTokens,
      [tokenId]: event.target.checked,
    }));
  };

  // Function to handle checkbox change for "Select All"
  const handleSelectAllChange = async (event) => {
    const newSelectedTokens = {};
    if (event.target.checked) {
      tokens.forEach((token) => {
        newSelectedTokens[token.pubkey] = true;
      });
    }
    setSelectedTokens(newSelectedTokens);
    setSelectAll(event.target.checked);
  };

  // Function to run when the user clicks the button
  const handleRunFunction = async () => {
    const selectedTokenIds = Object.keys(selectedTokens).filter(
      (tokenId) => selectedTokens[tokenId]
    );
    if (selectedTokenIds.length > 0) {
      let instructions = [];
      let result = [];

      selectedTokenIds.forEach((token) => {
        instructions.push(
          createCloseAccountInstruction(new PublicKey(token), publicKey, publicKey)
        );
      });

      const toastId = toast.loading("Processing...");
      let batchSize = 3;

      const txRes = await tx(
        publicKey,
        signAllTransactions,
        toastId,
        selectedTokenIds,
        instructions,
        result,
        batchSize
      );
      if (!txRes) {
        toast.error(`Transaction failed..`, {
          id: toastId,
          duration: 10000,
        });
        return;
      }
      const succ = txRes.reduce(
        (count, entry) => count + (entry.confirmed === true ? entry.batch.length : 0),
        0
      );
      if (succ > 0) {
        toast.success(`Closed ${succ} accounts.`, {
          id: toastId,
          duration: 60000,
        });
      } else {
        toast.error(`Transaction failed..`, {
          id: toastId,
          duration: 10000,
        });
      }
      getBalance();
    }
  };

  return (
    <Container sx={{ p: 1, display: "flex", justifyContent: "center" }}>
      <Box
        maxWidth="sm"
        width={"100%"}
        sx={{
          bgcolor: "bg.po",
          backdropFilter: "blur(10px)",
          borderRadius: 2,
        }}
      >
        <List dense>
          <ListItem key="header" sx={{ py: 1, pb: 2 }}>
            <ListItemAvatar>
              <Box display={"flex"} alignContent={"center"}>
                <Box display={"flex"} alignSelf={"center"} fontSize={16}>
                  <DoDisturbOnIcon fontSize="inherit" sx={{ pr: 1 }} color="green" />
                </Box>
                <Box fontSize={20} fontWeight={600} color={"green.main"}>
                  Redeem Rent
                </Box>
              </Box>
            </ListItemAvatar>
            <ListItemText primary="" />
            <Box
              color={"text.secondary"}
              sx={{ display: "flex", gap: "8px", alignItems: "center" }}
            >
              <ListItemText primary="select all" />
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAllChange}
                color="primary"
                size="small"
              />
            </Box>
          </ListItem>
          <Container sx={{ bgcolor: "bg.so", maxHeight: 500, overflow: "auto" }}>
            {tokens.map((token) => (
              <ListItem
                key={token.account.data.parsed.info.mint}
                disableGutters
                sx={{
                  borderBottom: 0.5,
                  borderColor: "bg.bord",
                }}
              >
                <ListItemText
                  primary={
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                      href={`https://solscan.io/account/${token.account.data.parsed.info.mint}`}
                    >
                      {token.account.data.parsed.info.mint.substring(0, 4)}..
                      {token.account.data.parsed.info.mint.substring(
                        token.account.data.parsed.info.mint.length - 4
                      )}
                    </a>
                  }
                />
                <Checkbox
                  checked={selectedTokens[token.pubkey] || false}
                  onChange={handleTokenCheckboxChange(token.pubkey)}
                  size="small"
                />
              </ListItem>
            ))}
            {tokens.length === 0 && (
              <ListItem
                key="zero"
                disableGutters
                color="white"
                sx={{ borderBottom: "solid 1px", borderColor: "background.paper" }}
              >
                <ListItemText color="text.primary" primary="Nothing to redeem" />
              </ListItem>
            )}
          </Container>

          <ListItem key="footer" sx={{ py: 3, width: "100%" }}>
            <Button
              disabled={selectedTokens.length < 1}
              variant="contained"
              color="green"
              fullWidth
              onClick={handleRunFunction}
              sx={{ py: 2, borderRadius: 2, fontWeight: 600, color: "black" }}
            >
              REDEEM
            </Button>
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};
