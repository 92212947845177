import { connection } from "../config";
import { ComputeBudgetProgram, TransactionMessage, VersionedTransaction } from "@solana/web3.js";
import toast from "react-hot-toast";
import { confirmTx } from "./confirm";

export async function tx(
  publicKey,
  signAllTransactions,
  toastId,
  array,
  instructions,
  result,
  batchSize,
  priorityFee
) {
  if (instructions.length < 1) {
    toast.error(`Transaction failed..`, {
      id: toastId,
      duration: 10000,
    });
    return false;
  }

  let latestBlockhash;

  try {
    latestBlockhash = await connection.getLatestBlockhash("confirmed");
  } catch {
    toast.error(`Transaction failed..`, {
      id: toastId,
      duration: 10000,
    });
    return false;
  }

  let batches = [];
  let transactions = [];

  for (let i = 0; i < instructions.length; i += batchSize) {
    let batch = instructions.slice(i, i + batchSize);
    if (priorityFee) {
      const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: 600000,
      });
      batch.unshift(addPriorityFee); // Add the priority fee to the top of the batch
    }
    const ba = array.slice(i / 2, (i + batchSize) / 2);
    let transaction;
    try {
      const messageV0 = new TransactionMessage({
        payerKey: publicKey,
        recentBlockhash: latestBlockhash.blockhash,
        instructions: batch,
      }).compileToV0Message();
      transaction = new VersionedTransaction(messageV0);
    } catch (err) {
      console.log(err.message);
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 10000,
      });
      return false;
    }
    batches.push(ba);
    transactions.push(transaction);
  }

  let signedTxs;

  try {
    signedTxs = await signAllTransactions(transactions, connection);
  } catch (err) {
    console.log(err.message);
    toast.error(`Transaction failed..`, {
      id: toastId,
      duration: 10000,
    });
    return false;
  }

  toast.loading(`Processing ${transactions.length} transaction(s)..`, {
    id: toastId,
  });

  let txId = [];

  for (const si of signedTxs) {
    const sitx = si.serialize();
    try {
      let tx = await connection.sendRawTransaction(sitx);
      txId.push(tx);
    } catch (err) {
      console.log(err.message);
    }
  }

  if (txId.length < 1) {
    toast.error(`Transaction failed..`, {
      id: toastId,
      duration: 10000,
    });
    return false;
  }

  for (const [i, tx] of txId.entries()) {
    const res = await confirmTx(tx);
    result.push({ batch: batches[i], tx: tx, confirmed: res });
  }

  return result;
}
