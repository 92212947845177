import { createBurnCheckedInstruction } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import toast from "react-hot-toast";
import { tx } from "./tx";

export async function burn(tokens, publicKey, signAllTransactions, toastId) {
  let instructions = [];
  let result = [];

  for (const token of tokens) {
    try {
      let instruction = createBurnCheckedInstruction(
        new PublicKey(token.ata), // PublicKey of Owner's Associated Token Account
        new PublicKey(token.mint), // Public Key of the Token Mint Address
        publicKey, // Public Key of Owner's Wallet
        token.amount, // Number of tokens to burn
        token.decimals, // Number of Decimals of the Token Mint
        [],
        new PublicKey(token.token_program)
      );
      instructions.push(instruction);
    } catch {
      continue;
    }
  }

  let batchSize = 10;

  const txRes = await tx(
    publicKey,
    signAllTransactions,
    toastId,
    tokens,
    instructions,
    result,
    batchSize
  );

  if (!txRes) return false;

  const succ = txRes.reduce(
    (count, entry) => count + (entry.confirmed === true ? entry.batch.length : 0),
    0
  );

  if (succ > 0) {
    toast.success(`Burn completed. ${succ} / ${tokens.length} tokens burnt.`, {
      id: toastId,
      duration: 60000,
    });
    return true;
  } else {
    return false;
  }
}
