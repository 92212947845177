/* global BigInt */
import React from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { rpc } from "../config";
import { useEffect, useState } from "react";
import { rlp } from "../functions/removeLP";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  Container,
  Typography,
  TextField,
  IconButton,
} from "@mui/material"; // Import Material-UI components
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import toast from "react-hot-toast";

export const ManageLP = () => {
  const { publicKey, sendTransaction } = useWallet();
  useEffect(() => {
    setTokens([]);
    if (publicKey) {
      getAssetsByOwner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  const [tokens, setTokens] = useState([]);
  const getAssetsByOwner = async () => {
    const response = await fetch(rpc, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: "my-id",
        method: "getAssetsByOwner",
        params: {
          ownerAddress: publicKey?.toString(),
          //tokenType: "fungible",
          page: 1, // Starts at 1
          limit: 1000,
          displayOptions: {
            showFungible: true, //return both fungible and non-fungible tokens
          },
        },
      }),
    });
    const { result } = await response.json();
    if (!result) return;
    const toks = result?.items.filter(
      (obj) => obj.interface === "FungibleToken" || obj.interface === "FungibleAsset"
    );
    setTokens(toks);
  };

  const [value, setValue] = useState(); // State to store the value from NumberInput

  const handleRemove = async (mint, decimals, ata) => {
    if (value) {
      const amount = BigInt(Math.floor(value * Math.pow(10, decimals)));
      const toastId = toast.loading("Processing...");
      await rlp(mint, decimals, ata, amount, publicKey, sendTransaction, toastId); // Pass the value to the rlp function
    }
  };

  return (
    <Container sx={{ p: 1, display: "flex", justifyContent: "center" }}>
      <Box
        maxWidth="sm"
        width={"100%"}
        sx={{
          bgcolor: "bg.po",
          backdropFilter: "blur(10px)",
          borderRadius: 2,
        }}
      >
        <List dense>
          <ListItem key="header" sx={{ py: 1, pb: 2 }}>
            <ListItemAvatar>
              <Box display={"flex"} alignContent={"center"} py={1}>
                <Box display={"flex"} alignSelf={"center"} fontSize={16}>
                  <DeleteForeverIcon fontSize="inherit" sx={{ pr: 1 }} color="secondary" />
                </Box>
                <Box fontSize={20} fontWeight={600} color={"secondary.main"}>
                  Withdraw LP
                </Box>
              </Box>
            </ListItemAvatar>
          </ListItem>
          <Container sx={{ bgcolor: "bg.so", maxHeight: 500, overflow: "auto" }}>
            {tokens.map((token) => (
              <ListItem
                key={token.id}
                disableGutters
                sx={{ borderBottom: "solid 1px", borderColor: "bg.p" }}
              >
                <ListItemAvatar>
                  {/* Assuming token content has an image URL stored in metadata */}
                  <Avatar
                    sx={{ width: 30, height: 30 }}
                    alt={token.content.metadata.symbol}
                    src={token.content.links.image}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    token.content.metadata.symbol ? (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                        href={`https://solscan.io/account/${token.id}`}
                      >
                        {token.content.metadata.symbol}
                      </a>
                    ) : (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                        href={`https://solscan.io/account/${token.id}`}
                      >
                        {token.id.substring(0, 4)}..{token.id.substring(token.id.length - 4)}
                      </a>
                    )
                  }
                  secondary={
                    <Typography
                      variant="caption"
                      color={"text.secondary"}
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <AccountBalanceWallet fontSize="xsmall" />
                      {parseFloat(
                        (
                          token.token_info.balance / Math.pow(10, token.token_info.decimals)
                        ).toFixed(2)
                      )}
                    </Typography>
                  }
                />
                <div style={{ display: "flex", gap: "8px" }}>
                  <NumberInput
                    maxValue={token.token_info.balance / Math.pow(10, token.token_info.decimals)}
                    onChange={(newValue) => setValue(newValue)} // Update the local value state
                  />
                  <div style={{ display: "flex" }}>
                    <IconButton
                      aria-label="remove"
                      onClick={() =>
                        handleRemove(
                          token.id,
                          token.token_info.decimals,
                          token.token_info.associated_token_address
                        )
                      }
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </div>
                </div>
              </ListItem>
            ))}
            {tokens.length === 0 && (
              <ListItem
                key="zero"
                disableGutters
                color="white"
                sx={{ borderBottom: "solid 1px", borderColor: "background.paper" }}
              >
                <ListItemText color="text.primary" primary="Nothing to withdraw" />
              </ListItem>
            )}
          </Container>
        </List>
      </Box>
    </Container>
  );
};

const NumberInput = ({ maxValue, onChange }) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value); // Call the onChange callback with the new value
  };

  const handleMax = () => {
    setValue(String(maxValue));
    onChange(String(maxValue)); // Call the onChange callback with the max value
  };

  return (
    <div>
      <TextField
        type="number"
        value={value}
        onChange={handleChange}
        size="small"
        sx={{ width: 120 }}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <Typography variant="caption" onClick={handleMax} style={{ cursor: "pointer" }}>
              MAX
            </Typography>
          ),
        }}
      />
    </div>
  );
};
