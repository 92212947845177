import { connection } from "../config";
import {
  jsonInfo2PoolKeys,
  struct,
  u8,
  u64,
  parseBigNumberish,
  generatePubKey,
  SPL_ACCOUNT_LAYOUT,
  LIQUIDITY_STATE_LAYOUT_V4,
} from "@raydium-io/raydium-sdk";
import { formatAmmKeysById } from "./formatAmmKeysById";
import {
  getAssociatedTokenAddress,
  createCloseAccountInstruction,
  NATIVE_MINT,
  TOKEN_PROGRAM_ID,
  createInitializeAccountInstruction,
} from "@solana/spl-token";
import { Transaction, TransactionInstruction, SystemProgram, PublicKey } from "@solana/web3.js";
import toast from "react-hot-toast";

export async function rlp(lpMint, decimals, ata, amount, publicKey, sendTransaction, toastId) {
  let feeWallet = process.env.REACT_APP_FEE_WALLET;
  let feeAmount = process.env.REACT_APP_LP_FEE_AMOUNT;

  try {
    const lpta = new PublicKey(ata);

    // Add your custom logic or API call here
    const programId = new PublicKey("675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8");
    // getpa for mint
    let allAmmAccount = await connection.getProgramAccounts(programId, {
      filters: [
        { dataSize: 752 },
        {
          memcmp: {
            offset: 464,
            bytes: lpMint,
          },
        },
      ],
    });
    // filter / format the data
    const amAccountmData = allAmmAccount
      .map((i) => ({
        id: i.pubkey,
        programId: i.account.owner,
        ...LIQUIDITY_STATE_LAYOUT_V4.decode(i.account.data),
      }))
      .filter((i) => i.marketProgramId.toString() !== PublicKey.default.toString());
    let poolId;
    for (const am of amAccountmData) {
      poolId = am.id;
    }

    if (amAccountmData.length === 0) {
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 10000,
      });
      return false;
    }

    const targetPoolInfo = await formatAmmKeysById(poolId);
    if (!targetPoolInfo) {
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 10000,
      });
      return false;
    }
    const newAccount = generatePubKey({ fromPublicKey: publicKey, programId: TOKEN_PROGRAM_ID });

    targetPoolInfo.id = targetPoolInfo.id.toString();
    // -------- step 1: make instructions --------
    const poolKeys = jsonInfo2PoolKeys(targetPoolInfo);

    const bmta = await getAssociatedTokenAddress(new PublicKey(targetPoolInfo.baseMint), publicKey);

    const LAYOUT = struct([u8("instruction"), u64("amountIn")]);
    const data = Buffer.alloc(LAYOUT.span);
    LAYOUT.encode(
      {
        instruction: 4,
        amountIn: parseBigNumberish(amount),
      },
      data
    );

    const userKeys = {
      lpTokenAccount: lpta,
      baseTokenAccount: bmta,
      quoteTokenAccount: newAccount.publicKey,
      owner: publicKey,
    };

    const keys = [
      // spl token
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
      // amm
      { pubkey: poolKeys.id, isSigner: false, isWritable: true },
      { pubkey: poolKeys.authority, isSigner: false, isWritable: false },
      { pubkey: poolKeys.openOrders, isSigner: false, isWritable: true },
      { pubkey: poolKeys.targetOrders, isSigner: false, isWritable: true },
      { pubkey: poolKeys.lpMint, isSigner: false, isWritable: true },
      { pubkey: poolKeys.baseVault, isSigner: false, isWritable: true },
      { pubkey: poolKeys.quoteVault, isSigner: false, isWritable: true },
      { pubkey: poolKeys.withdrawQueue, isSigner: false, isWritable: true },
      { pubkey: poolKeys.lpVault, isSigner: false, isWritable: true },
      // serum
      { pubkey: poolKeys.marketProgramId, isSigner: false, isWritable: false },
      { pubkey: poolKeys.marketId, isSigner: false, isWritable: true },
      { pubkey: poolKeys.marketBaseVault, isSigner: false, isWritable: true },
      { pubkey: poolKeys.marketQuoteVault, isSigner: false, isWritable: true },
      { pubkey: poolKeys.marketAuthority, isSigner: false, isWritable: false },
      // user
      { pubkey: userKeys.lpTokenAccount, isSigner: false, isWritable: true },
      { pubkey: userKeys.baseTokenAccount, isSigner: false, isWritable: true },
      { pubkey: userKeys.quoteTokenAccount, isSigner: false, isWritable: true },
      { pubkey: userKeys.owner, isSigner: true, isWritable: false },
      // serum orderbook
      { pubkey: poolKeys.marketEventQueue, isSigner: false, isWritable: true },
      { pubkey: poolKeys.marketBids, isSigner: false, isWritable: true },
      { pubkey: poolKeys.marketAsks, isSigner: false, isWritable: true },
    ];

    const instructions = [
      SystemProgram.createAccountWithSeed({
        fromPubkey: publicKey,
        basePubkey: publicKey,
        seed: newAccount.seed,
        newAccountPubkey: newAccount.publicKey,
        lamports: 2039280,
        space: SPL_ACCOUNT_LAYOUT.span,
        programId: TOKEN_PROGRAM_ID,
      }),
      // init token account
      createInitializeAccountInstruction(newAccount.publicKey, NATIVE_MINT, publicKey),
      // Tx
      new TransactionInstruction({
        programId: poolKeys.programId,
        keys: keys,
        data: data,
      }),
      // close aux account
      createCloseAccountInstruction(newAccount.publicKey, publicKey, publicKey),
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: new PublicKey(feeWallet),
        lamports: feeAmount * Math.pow(10, 9),
      }),
    ];

    const transaction = new Transaction();

    transaction.add(instructions[0]);
    transaction.add(instructions[1]);
    transaction.add(instructions[2]);
    transaction.add(instructions[3]);
    transaction.add(instructions[4]);

    const latestBlockHash = await connection.getLatestBlockhash();
    transaction.recentBlockhash = latestBlockHash.blockhash;

    try {
      const txid = await sendTransaction(transaction, connection);
      try {
        const confirm = await connection.confirmTransaction(
          {
            blockhash: latestBlockHash.blockhash,
            lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
            signature: txid,
          },
          "confirmed"
        );

        if (confirm.value.err === null) {
          console.log(txid);
          toast.success(`LP removed successfully.`, {
            id: toastId,
            duration: 60000,
          });
          return true;
        } else {
          toast.error(`Transaction failed..`, {
            id: toastId,
            duration: 10000,
          });
          return false;
        }
      } catch (error) {
        toast.error(`Transaction failed..`, {
          id: toastId,
          duration: 10000,
        });
        return false;
      }
    } catch (error) {
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 10000,
      });
      return false;
    }
  } catch (error) {
    toast.error(`Transaction failed..`, {
      id: toastId,
      duration: 10000,
    });
    return false;
  }
}
