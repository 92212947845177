import "./App.css";
import { Nav } from "./components/nav";
import { useWallet } from "@solana/wallet-adapter-react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { ManageLP } from "./components/lp";
import { Burn } from "./components/burn";
import { Empty } from "./components/empty";
import { Swap } from "./components/swap";
import { BulkSwap } from "./components/bulkSwap";
import { Airdrop } from "./components/airdrop";
import { PumpFarm } from "./components/pumpFarm";
import { Landing } from "./components/landing";
import { Container, Box } from "@mui/material";
import { Toaster } from "react-hot-toast";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import AssessmentIcon from "@mui/icons-material/Assessment";
// import the styles
require("@solana/wallet-adapter-react-ui/styles.css");
require("./style.css");

function App() {
  const { publicKey } = useWallet();
  return (
    <Box minHeight={"100vh"} flexGrow={1} display={"flex"} flexDirection={"column"}>
      <Router>
        <Nav />
        <Container sx={{ pb: 5 }}>
          <Routes>
            <Route path="/" element={<Landing />} />
            {publicKey ? (
              <>
                <Route path="/swap" element={<Swap />} />
                <Route path="/pumpFarm" element={<PumpFarm />} />
                <Route path="/bulkswap" element={<BulkSwap />} />
                <Route path="/managelp" element={<ManageLP />} />
                <Route path="/burn" element={<Burn />} />
                <Route path="/empty" element={<Empty />} />
                <Route path="/airdrop" element={<Airdrop />} />
                <Route path="*" element={<Landing />} />
              </>
            ) : (
              <Route path="*" element={<WalletMultiButton />} />
            )}
          </Routes>
        </Container>
      </Router>
      <Box
        bgcolor="bg.po"
        marginTop={"auto"}
        component="footer"
        textAlign={"center"}
        gap={2}
        p={5}
        display={"flex"}
        alignContent={"center"}
        justifyContent={"center"}
        bottom={"0"} /* Set bottom to "0" */
      >
        <a
          href="https://twitter.com/sol5io"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <XIcon fontSize="large" />
        </a>
        <a
          href="https://t.me/sol5io"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <TelegramIcon fontSize="large" />
        </a>
        <a
          href="https://dexscreener.com"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", display: "none" }}
        >
          <AssessmentIcon fontSize="large" />
        </a>
      </Box>
      <Box textAlign={"center"} p={1} fontSize={12} bgcolor={"bg.s"} color={"text.secondary"}>
        This application is still in BETA
      </Box>

      <Toaster
        position="bottom-right"
        toastOptions={{
          className: "",
          style: {
            padding: "16px",
            color: "#ffff",
            background: "#000000",
          },
        }}
      />
      {/** 
      <div className="background">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>*/}
    </Box>
  );
}

export default App;
