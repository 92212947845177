import React, { useEffect } from "react";
import { Grid, Container, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import sol5 from "../assets/s5bo.png";
import { useWallet } from "@solana/wallet-adapter-react";
import ParaglidingIcon from "@mui/icons-material/Paragliding";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import LinkIcon from "@mui/icons-material/Link";

export const Landing = () => {
  const { publicKey /**signMessage*/ } = useWallet();
  useEffect(() => {
    //signW();
  }, [publicKey]);

  /**async function signW() {
    try {
      // `publicKey` will be null if the wallet isn't connected
      if (!publicKey) return;
      // `signMessage` will be undefined if the wallet doesn't support it
      if (!signMessage) throw new Error("Wallet does not support message signing!");
      // Encode anything as bytes
      const message = new TextEncoder().encode("Hello, world!");
      // Sign the bytes using the wallet
      const signature = await signMessage(message);

      console.log(signature);
    } catch (error) {
      console.log(error);
    }
    // Verify that the bytes were signed using the private key that matches the known public key
    //if (!verify(signature, message, publicKey.toBytes())) throw new Error("Invalid signature!");
  }*/

  let boxes = [
    {
      link: "swap",
      icon: <SwapHorizIcon fontSize="inherit" />,
      text: "Swap Tokens",
      desc: "Swap any two tokens",
    },
    {
      link: "bulkswap",
      icon: <BlurOnIcon fontSize="inherit" />,
      text: "Bulk Swap",
      desc: "Swap many tokens at once in to Solana",
    },
    {
      link: "pumpFarm",
      icon: <LinkIcon fontSize="inherit" />,
      text: "Pump Swap",
      desc: "Swap or farm microbuys on Pump.fun",
    },
    {
      link: "burn",
      icon: <LocalFireDepartmentIcon fontSize="inherit" />,
      text: "Burn Tokens",
      desc: "Burn unwanted / liquidity tokens",
    },
    {
      link: "empty",
      icon: <DoDisturbOnIcon fontSize="inherit" />,
      text: "Redeem Rent",
      desc: "Close empty token accounts and redeem Solana",
    },
    {
      link: "airdrop",
      icon: <ParaglidingIcon fontSize="inherit" />,
      text: "Mass Airdrop",
      desc: "Airdrop your token to the masses",
    },
    {
      link: "managelp",
      icon: <ManageAccountsIcon fontSize="inherit" />,
      text: "Remove Liquidity",
      desc: "Quickly remove liquidity from Raydium LPs",
    },
  ];

  return (
    <Container>
      <Box textAlign="center" pb={1}>
        <img src={sol5} alt="cs_office" style={{ maxWidth: "90%", maxHeight: 200 }} />
      </Box>
      <Grid container spacing={4} p={4} justifyContent={"center"}>
        {boxes.map((box) => (
          <Grid item xs={12} sm={4} key={box.link}>
            <Link to={box.link} style={{ textDecoration: "none" }}>
              <Box
                maxWidth="sm"
                width={"100%"}
                sx={{
                  bgcolor: "bg.po",
                  borderRadius: 2,
                  backdropFilter: "blur(10px)",
                  height: "100%",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#ffffff12")} // Change background color on hover
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff08")} // Revert back to original background color on hover out
              >
                <Box
                  justifyContent={"center"}
                  justifyItems={"center"}
                  textAlign={"center"}
                  display={"flex"}
                  flexDirection={"column"}
                  p={2}
                  py={4}
                >
                  <Box textAlign={"center"}>
                    <Typography color={"green.main"} variant="h3">
                      {box.icon}
                    </Typography>
                  </Box>
                  <Box textAlign={"center"}>
                    <Typography fontWeight={600} variant="h6" component="h2" gutterBottom>
                      {box.text}
                    </Typography>
                  </Box>
                  <Box textAlign={"center"}>
                    <Typography variant="body1" color={"text.secondary"}>
                      {box.desc}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
