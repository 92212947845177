import React from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  Container,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  Button,
  Typography,
  Modal,
  List,
  ListItemText,
  InputBase,
  IconButton,
  Box,
} from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { searchAssets } from "../functions/searchAssets";
import { getAsset } from "../functions/getAsset";
import { multiSwap } from "../functions/jupSwap";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const Swap = () => {
  const { publicKey, signAllTransactions } = useWallet();
  const [assets, setAssets] = useState();

  const [inputCurrency, setInputCurrency] = useState("So11111111111111111111111111111111111111112");
  const [inputSymbol, setInputSymbol] = useState("SOL");
  const [inputIcon, setInputIcon] = useState(
    "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png"
  );
  const [inputAmount, setInputAmount] = useState("");
  const [inputBalance, setInputBalance] = useState(0);
  const [inputDecimal, setInputDecimal] = useState(9);
  const [outputCurrency, setOutputCurrency] = useState(
    "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
  );
  const [outputSymbol, setOutputSymbol] = useState("USDC");
  const [outputIcon, setOutputIcon] = useState(
    "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png"
  );
  const [outputAmount, setOutputAmount] = useState("");
  const [outputBalance, setOutputBalance] = useState(0);
  const [outputDecimal, setOutputDecimal] = useState(6);
  const [slippage, setSlippage] = useState(1);
  const [topTokens, setTopTokens] = useState([]);

  const [openInput, setOpenInput] = useState(false);
  const [openOutput, setOpenOutput] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [jup, setJup] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [myTokens, setMyTokens] = useState([]);

  useEffect(() => {
    getA();
  }, [publicKey]);

  async function getA() {
    if (publicKey) {
      setJup([]);
      const res = await searchAssets(publicKey);
      if (!res) return;
      setAssets(res);
      const filteredArray = res.items.filter((item) =>
        item.token_info.hasOwnProperty("price_info")
      );
      const myItems = filteredArray.map((item) => ({
        address: item.id,
        logoURI: item.content.links.image,
        name: item.content.metadata.name,
        symbol: item.content.metadata.symbol,
        decimals: item.token_info.decimals,
        balance: item.token_info.balance / Math.pow(10, item.token_info.decimals),
        value: item.token_info.price_info.total_price,
      }));

      myItems.push({
        address: "So11111111111111111111111111111111111111112",
        logoURI:
          "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
        symbol: "SOL",
        name: "Solana",
        decimals: 9,
        balance: res.nativeBalance.lamports / Math.pow(10, 9),
        value: res.nativeBalance.total_price,
      });

      myItems.sort((a, b) => b.value - a.value);

      setMyTokens(myItems);
    }
  }

  useEffect(() => {
    const pattern = /^[a-zA-Z0-9]{41,}$/;

    getRate(inputAmount, "input");

    if (assets && pattern.test(inputCurrency)) {
      getBalance(inputCurrency, "input");
    }
    if (assets && pattern.test(outputCurrency)) {
      getBalance(outputCurrency, "output");
    }
  }, [inputCurrency, outputCurrency, assets]);

  useEffect(() => {
    setSearchResults(jup);
    const filter = jup.filter((item) => {
      return (
        item.name.toLowerCase().startsWith(searchQuery) ||
        item.symbol.toLowerCase().startsWith(searchQuery) ||
        item.address.startsWith(searchQuery)
      );
    });

    setSearchResults(filter);

    if (filter.length === 0) {
      const pattern = /^[a-zA-Z0-9]{41,}$/;
      if (pattern.test(searchQuery)) {
        async function getA() {
          const res = await getAsset(searchQuery);
          if (res?.content) {
            const results = [
              {
                symbol: res?.content.metadata.symbol,
                decimals: res?.token_info.decimals,
                logoURI: res?.content.links.image,
                address: searchQuery,
              },
            ];
            setSearchResults(results);
          }
        }
        getA();
      }
    }
  }, [searchQuery]);

  const handleSwapCurrencies = () => {
    const tempCurrency = inputCurrency;
    const tempI = inputIcon;
    const tempD = inputDecimal;
    const tempSymbol = inputSymbol;
    setInputCurrency(outputCurrency);
    setInputAmount(outputAmount);
    setInputDecimal(outputDecimal);
    setInputSymbol(outputSymbol);
    setInputIcon(outputIcon);
    setOutputCurrency(tempCurrency);
    setOutputDecimal(tempD);
    setOutputSymbol(tempSymbol);
    setOutputIcon(tempI);
    setOutputAmount("");
  };

  /**const handleInputCurrencyChange = async (e) => {
    const pattern = /^[a-zA-Z0-9]{41,}$/;
    setInputSymbol("");
    setInputCurrency(e.target.value);
    if (e.target.value === "SOL") {
      setInputCurrency("So11111111111111111111111111111111111111112");
      setInputSymbol("SOL");
      setInputIcon(
        "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png"
      );
      setInputDecimal(9);
    } else if (pattern.test(e.target.value)) {
      const res = await getAsset(e.target.value);
      if (res?.content) {
        setInputSymbol(res?.content.metadata.symbol);
        setInputDecimal(res?.token_info.decimals);
        setInputIcon(res?.content.links.image);
      }
      console.log(res);
    }
  };

  const handleOutputCurrencyChange = async (e) => {
    const pattern = /^[a-zA-Z0-9]{41,}$/;
    setOutputSymbol("");
    setOutputCurrency(e.target.value);
    if (e.target.value === "SOL") {
      setOutputCurrency("So11111111111111111111111111111111111111112");
      setOutputSymbol("SOL");
      setOutputIcon(
        "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png"
      );
      setOutputDecimal(9);
    } else if (pattern.test(e.target.value)) {
      const res = await getAsset(e.target.value);
      if (res?.content) {
        setOutputSymbol(res?.content.metadata.symbol);
        setOutputDecimal(res?.token_info.decimals);
        setOutputIcon(res?.content.links.image);
      }
      console.log(res);
    }
  };
*/
  const handleInputAmountChange = (e) => {
    setInputAmount(e.target.value);
    getRate(e.target.value, "input");
  };

  const handleOutputAmountChange = (e) => {
    setOutputAmount(e.target.value);
    getRate(e.target.value, "output");
  };

  const handleInMax = () => {
    setInputAmount(inputBalance);
    getRate(inputBalance, "input");
  };

  const handleOutMax = () => {
    setOutputAmount(outputBalance);
    getRate(outputBalance, "output");
  };

  const handleSlippageChange = (e) => {
    setSlippage(e.target.value);
  };

  const handleSwap = async () => {
    if ((inputCurrency, outputCurrency, inputAmount, inputDecimal)) {
      const toastId = toast.loading("Processing...");
      const res = await multiSwap(
        [{ id: inputCurrency, balance: Math.floor(inputAmount * Math.pow(10, inputDecimal)) }],
        outputCurrency,
        slippage,
        publicKey,
        signAllTransactions,
        toastId
      );
      if (res) getA();
    }
  };
  /**
  const handleRefresh = () => {
    // Call the getRate function with inputAmount and "input" type
    getRate(inputAmount, "input");
  }; */

  const handleOpenInput = async () => {
    setOpenInput(true);
    if (jup.length === 0) {
      const resp = await fetch("https://token.jup.ag/strict");
      const list = await resp.json();
      setSearchQuery("");
      let merged = myTokens.concat(list);
      // Use a Set to keep track of unique IDs
      const uniqueIds = new Set();
      // Filter out duplicates based on the "id" property
      const uniqueArray = merged.filter((obj) => {
        if (!uniqueIds.has(obj.address)) {
          uniqueIds.add(obj.address);
          return true;
        }
        return false;
      });
      setSearchResults(uniqueArray);
      setTopTokens(list.slice(0, 6));
      setJup(uniqueArray);
    }
  };

  const handleCloseInput = () => {
    setOpenInput(false);
    setSearchQuery("");
  };

  const handleOpenOutput = async () => {
    setOpenOutput(true);

    if (jup.length === 0) {
      const resp = await fetch("https://token.jup.ag/strict");
      const list = await resp.json();
      setSearchQuery("");
      let merged = myTokens.concat(list);
      // Use a Set to keep track of unique IDs
      const uniqueIds = new Set();
      // Filter out duplicates based on the "id" property
      const uniqueArray = merged.filter((obj) => {
        if (!uniqueIds.has(obj.address)) {
          uniqueIds.add(obj.address);
          return true;
        }
        return false;
      });
      setSearchResults(uniqueArray);
      setTopTokens(list.slice(0, 6));
      setJup(uniqueArray);
    }
  };

  const handleCloseOutput = () => {
    setOpenOutput(false);
    setSearchQuery("");
  };

  const handleInputSelect = (item) => {
    setInputCurrency(item.address);
    setInputIcon(item.logoURI);
    setInputDecimal(item.decimals);
    setInputSymbol(item.symbol);
    setOpenInput(false);
    setSearchQuery("");
  };

  const handleOutputSelect = (item) => {
    setOutputCurrency(item.address);
    setOutputIcon(item.logoURI);
    setOutputSymbol(item.symbol);
    setOutputDecimal(item.decimals);
    setOpenOutput(false);
    setSearchQuery("");
  };

  async function getRate(amount, type) {
    const pattern = /^[a-zA-Z0-9]{41,}$/;
    if (publicKey && pattern.test(inputCurrency) && pattern.test(outputCurrency) && amount > 0) {
      let swapm;
      if (type === "input") {
        swapm = "ExactIn";
        amount = amount * Math.pow(10, inputDecimal);
      } else if (type === "output") {
        swapm = "ExactOut";
        amount = amount * Math.pow(10, outputDecimal);
      }
      const quoteResponse = await fetch(
        `https://quote-api.jup.ag/v6/quote?inputMint=${inputCurrency}&outputMint=${outputCurrency}&amount=${Math.floor(
          amount
        )}&slippageBps=${slippage * 100}&swapMode=${swapm}`
      );
      const qr = await quoteResponse.json();
      if (qr.error) {
        console.log(qr.error);
        return;
      }

      if (type === "input") {
        setOutputAmount(qr.outAmount / Math.pow(10, outputDecimal));
      } else if (type === "output") {
        setInputAmount(qr.inAmount / Math.pow(10, inputDecimal));
      }
      console.log(qr);
    }
  }

  async function getBalance(mint, type) {
    const holding = assets?.items.filter((item) => item.id === mint);
    if (type === "input") {
      if (mint === "So11111111111111111111111111111111111111112") {
        setInputBalance(assets?.nativeBalance.lamports / Math.pow(10, 9));
      } else if (holding?.length > 0) {
        setInputBalance(
          holding[0].token_info.balance / Math.pow(10, holding[0].token_info.decimals)
        );
      } else {
        setInputBalance(0);
      }
    } else {
      if (mint === "So11111111111111111111111111111111111111112") {
        setOutputBalance(assets?.nativeBalance.lamports / Math.pow(10, 9));
      } else if (holding?.length > 0) {
        setOutputBalance(
          holding[0].token_info.balance / Math.pow(10, holding[0].token_info.decimals)
        );
      } else {
        setOutputBalance(0);
      }
    }
  }

  return (
    <Container sx={{ p: 1, display: "flex", justifyContent: "center" }}>
      <Box
        maxWidth="sm"
        width={"100%"}
        sx={{
          bgcolor: "bg.po",
          backdropFilter: "blur(10px)",
          borderRadius: 2,
        }}
      >
        <Box borderRadius={2} py={1}>
          <Box sx={{ display: "flex", p: 1, pt: 2 }} justifyContent={"space-between"}>
            <Box display={"flex"} alignContent={"center"} pl={1}>
              <Box display={"flex"} alignSelf={"center"} fontSize={16}>
                <ArrowCircleRightIcon fontSize="inherit" sx={{ pr: 1 }} color="error" />
              </Box>
              <Box fontSize={20} fontWeight={600} color={"error.main"}>
                Sell
              </Box>
            </Box>
            <Typography
              variant="body2"
              color="text.secondary"
              align="left"
              onClick={handleInMax}
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            >
              <AccountBalanceWalletIcon fontSize="xsmall" sx={{ pr: 0.5 }} />
              {parseFloat(inputBalance.toFixed(4))} {inputSymbol}
            </Typography>
          </Box>
          <Box display={"flex"} bgcolor={"bg.so"} p={1}>
            <Box
              display={"flex"}
              gap={1}
              p={1}
              alignItems={"center"}
              alignContent={"center"}
              onClick={handleOpenInput}
              sx={{ cursor: "pointer" }}
            >
              <Avatar alt={inputSymbol} src={inputIcon} sx={{ width: 20, height: 20 }} />
              <Typography fontWeight={600} display={"flex"}>
                {inputSymbol} <ArrowDropDownIcon fontSize="small" />
              </Typography>
            </Box>
            <InputBase
              placeholder="0.00"
              fullWidth
              value={inputAmount}
              onChange={handleInputAmountChange}
              sx={{ p: 1, borderRadius: 2 }}
              inputProps={{ style: { textAlign: "right" } }}
            />
          </Box>
        </Box>
        <Box textAlign={"center"}>
          <IconButton aria-label="swap" onClick={handleSwapCurrencies}>
            <SwapVertIcon />
          </IconButton>
        </Box>
        <Box borderRadius={2} pb={1}>
          <Box sx={{ display: "flex", p: 1 }} justifyContent={"space-between"}>
            <Box display={"flex"} alignContent={"center"} pl={1}>
              <Box display={"flex"} alignSelf={"center"} fontSize={16}>
                <ArrowCircleLeftIcon fontSize="inherit" sx={{ pr: 1 }} color="success" />
              </Box>
              <Box fontSize={20} fontWeight={600} color={"success.main"}>
                Buy
              </Box>
            </Box>
            <Typography
              variant="body2"
              color="text.secondary"
              align="left"
              onClick={handleOutMax}
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            >
              <AccountBalanceWalletIcon fontSize="xsmall" sx={{ pr: 0.5 }} />
              {parseFloat(outputBalance.toFixed(4))} {outputSymbol}
            </Typography>
          </Box>
          <Box display={"flex"} bgcolor={"bg.so"} p={1}>
            <Box
              display={"flex"}
              gap={1}
              p={1}
              alignItems={"center"}
              alignContent={"center"}
              onClick={handleOpenOutput}
              sx={{ cursor: "pointer" }}
            >
              <Avatar alt={outputSymbol} src={outputIcon} sx={{ width: 20, height: 20 }} />
              <Typography fontWeight={600} display={"flex"}>
                {outputSymbol} <ArrowDropDownIcon fontSize="small" />
              </Typography>
            </Box>
            <InputBase
              placeholder="0.00"
              fullWidth
              value={outputAmount}
              onChange={handleOutputAmountChange}
              sx={{ p: 1, borderRadius: 2 }}
              inputProps={{ style: { textAlign: "right" } }}
            />
          </Box>
        </Box>
        <Box
          px={2}
          justifyContent={"right"}
          textAlign={"right"}
          display={"flex"}
          alignItems={"center"}
          gap={0.5}
          color={"text.secondary"}
        >
          <Box fontSize={14}>SLIPPAGE</Box>
          <InputBase
            size="small"
            value={slippage}
            onChange={handleSlippageChange}
            sx={{ width: 20, fontSize: 14, mt: 0.5, color: "text.secondary" }}
            inputProps={{ style: { textAlign: "right" } }}
          />
          <Box fontSize={14}>%</Box>
        </Box>
        <Box pt={2} pb={3} px={2} textAlign={"right"}>
          <Button
            disabled={!inputAmount}
            size="large"
            variant="contained"
            color="green"
            onClick={handleSwap}
            fullWidth
            sx={{ py: 2, borderRadius: 2, fontWeight: 600, color: "black" }}
          >
            SWAP
          </Button>
        </Box>
      </Box>

      {/* Buy Modal */}
      <Modal
        open={openInput}
        onClose={handleCloseInput}
        aria-labelledby="select-token"
        aria-describedby="select-token"
      >
        <Box
          borderRadius={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            maxWidth: "95%",
            bgcolor: "bg.p",
            boxShadow: "0px 0px 5px 2px #000000",
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            justifyContent={"space-between"}
            p={2}
          >
            <Box>
              <SearchIcon fontSize="small" color="green" />
            </Box>
            <Box width={"100%"}>
              <InputBase
                fullWidth
                placeholder="Enter Token Address"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Box>
            <Box>
              <IconButton onClick={handleCloseInput} size="small" color="green">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <Box px={1} pb={1} sx={{ display: "flex", flexWrap: "wrap" }}>
            {topTokens.map((result) => (
              <Button
                key={result.address}
                sx={{
                  color: "white",
                  m: 0.5,
                  p: 0.6,
                  px: 0.9,
                  bgcolor: "bg.so",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 2,
                  borderColor: "bg.s",
                }}
                variant="outlined"
                size="small"
                color="green"
                onClick={() => handleInputSelect(result)}
              >
                <Avatar src={result.logoURI} sx={{ width: "24px", height: "24px", mr: 1 }} />
                {result.symbol}
              </Button>
            ))}
          </Box>

          <List dense sx={{ maxHeight: "60vh", overflowY: "auto", bgcolor: "bg.so" }}>
            {searchResults.map((result) => (
              <ListItemButton key={result.address} onClick={() => handleInputSelect(result)}>
                <ListItemAvatar>
                  <Avatar src={result.logoURI} sx={{ width: "24px", height: "24px" }} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body2" fontWeight={600}>
                      {result.symbol}
                    </Typography>
                  }
                  secondary={
                    <Typography component="span" variant="caption" color="text.secondary">
                      {result.address.substring(0, 4)}...
                      {result.address.substring(result.address.length - 4)}
                    </Typography>
                  }
                />
                <ListItemText
                  sx={{ textAlign: "end" }}
                  primary={
                    <Typography variant="body2">
                      {result?.balance ? parseFloat(result.balance.toFixed(4)) : ""}
                    </Typography>
                  }
                  secondary={
                    <Typography component="span" variant="caption" color="text.secondary">
                      {result?.value ? `$${parseFloat(result.value.toFixed(2))}` : ""}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Modal>

      {/* Sell Modal */}
      <Modal
        open={openOutput}
        onClose={handleCloseOutput}
        aria-labelledby="select-token"
        aria-describedby="select-token"
      >
        <Box
          borderRadius={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            maxWidth: "95%",
            bgcolor: "bg.p",
            boxShadow: "0px 0px 5px 2px #000000",
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            justifyContent={"space-between"}
            p={2}
          >
            <Box>
              <SearchIcon fontSize="small" color="green" />
            </Box>
            <Box width={"100%"}>
              <InputBase
                fullWidth
                placeholder="Enter Token Address"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Box>
            <Box>
              <IconButton onClick={handleCloseOutput} size="small" color="green">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <Box px={1} pb={1} sx={{ display: "flex", flexWrap: "wrap" }}>
            {topTokens.map((result) => (
              <Button
                key={result.address}
                sx={{
                  color: "white",
                  m: 0.5,
                  p: 0.6,
                  px: 0.9,
                  bgcolor: "bg.so",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 2,
                  borderColor: "bg.s",
                }}
                variant="outlined"
                size="small"
                color="green"
                onClick={() => handleOutputSelect(result)}
              >
                <Avatar src={result.logoURI} sx={{ width: "24px", height: "24px", mr: 1 }} />
                {result.symbol}
              </Button>
            ))}
          </Box>

          <List dense sx={{ maxHeight: "60vh", overflowY: "auto", bgcolor: "bg.so" }}>
            {searchResults.map((result) => (
              <ListItemButton key={result.address} onClick={() => handleOutputSelect(result)}>
                <ListItemAvatar>
                  <Avatar src={result.logoURI} sx={{ width: "24px", height: "24px" }} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body2" fontWeight={600}>
                      {result.symbol}
                    </Typography>
                  }
                  secondary={
                    <Typography component="span" variant="caption" color="text.secondary">
                      {result.address.substring(0, 4)}...
                      {result.address.substring(result.address.length - 4)}
                    </Typography>
                  }
                />
                <ListItemText
                  sx={{ textAlign: "end" }}
                  primary={
                    <Typography variant="body2">
                      {result?.balance ? parseFloat(result.balance.toFixed(4)) : ""}
                    </Typography>
                  }
                  secondary={
                    <Typography component="span" variant="caption" color="text.secondary">
                      {result?.value ? `$${parseFloat(result.value.toFixed(2))}` : ""}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Modal>
    </Container>
  );
};
