/* global BigInt */
import React from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { rpc } from "../config";
import { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  TextField,
  Avatar,
  Typography,
  Checkbox,
  Container,
  Box,
  InputBase,
  Button,
} from "@mui/material"; // Import Material-UI components
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import { multiSwap } from "../functions/jupSwap";
import toast from "react-hot-toast";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

export const BulkSwap = () => {
  const { publicKey, signAllTransactions } = useWallet();
  const [tokens, setTokens] = useState([]);
  const [selectedTokens, setSelectedTokens] = useState([]); // State to store selected tokens
  const [selectAll, setSelectAll] = useState(false);
  const [notTrade, setNotTrade] = useState([]);
  const [solValue, setSolValue] = useState(0.0); // State for the selected value
  const [slippage, setSlippage] = useState(1);
  const receiveValue = "So11111111111111111111111111111111111111112";

  useEffect(() => {
    setTokens([]);
    if (publicKey) {
      getAssetsByOwner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  useEffect(() => {
    // Filter selected tokens
    const selected = Object.entries(selectedTokens).filter(([_, amount]) => amount > 0);
    let sel = [];
    for (const [tokenId, amount] of selected) {
      const token = tokens.find((t) => t.id === tokenId);
      if (token) {
        sel.push({
          id: token.id,
          balance: BigInt(amount),
          symbol: token.content.metadata.symbol,
        });
      }
    }
    if (sel.length > 0) {
      getValue(sel);
    } else {
      setSolValue(0);
    }
  }, [selectedTokens, tokens]);

  const getValue = async (sel) => {
    let amount = 0;
    for (const token of sel) {
      const quoteResponse = await (
        await fetch(
          `https://quote-api.jup.ag/v6/quote?inputMint=${token.id}&outputMint=${receiveValue}&amount=${token.balance}&slippageBps=100`
        )
      ).json();
      if (quoteResponse.error) {
        console.log(quoteResponse.error);
        setNotTrade((prevItems) => [...prevItems, token.id]);
        toast.error(`${token.symbol} not tradeable.`, { duration: 3000 });
        continue;
      }
      amount += quoteResponse.outAmount / Math.pow(10, 9);
    }
    setSolValue(amount);
  };

  const getAssetsByOwner = async () => {
    const response = await fetch(rpc, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: "my-id",
        method: "getAssetsByOwner",
        params: {
          ownerAddress: publicKey?.toString(),
          //tokenType: "fungible",
          page: 1, // Starts at 1
          limit: 1000,
          displayOptions: {
            showFungible: true, //return both fungible and non-fungible tokens
          },
        },
      }),
    });
    const { result } = await response.json();
    if (!result) return;
    const toks = result?.items.filter(
      (obj) => obj.interface === "FungibleToken" || obj.interface === "FungibleAsset"
    );
    setTokens(toks);
  };

  const handleTokenSelect = (tokenId, maxAmount) => (event) => {
    const isChecked = event.target.checked;
    setSelectedTokens((prevSelected) => ({
      ...prevSelected,
      [tokenId]: isChecked ? maxAmount : 0,
    }));
  };

  const handleNuke = async () => {
    // Filter selected tokens
    const selected = Object.entries(selectedTokens).filter(([_, amount]) => amount > 0);
    let sel = [];
    for (const [tokenId, amount] of selected) {
      const token = tokens.find((t) => t.id === tokenId);
      if (token && !notTrade.includes(token.id)) {
        sel.push({
          id: token.id,
          balance: BigInt(amount),
        });
      }
    }
    if (sel.length > 0) {
      console.log(sel);
      const toastId = toast.loading("Processing...");
      const res = await multiSwap(
        sel,
        receiveValue,
        slippage,
        publicKey,
        signAllTransactions,
        toastId
      );
      if (res) getAssetsByOwner();
    }
  };

  const handleSelectAll = () => {
    const newSelectedTokens = {};
    tokens.forEach((token) => {
      newSelectedTokens[token.id] = selectAll ? 0 : token.token_info.balance;
    });
    setSelectedTokens(newSelectedTokens);
    setSelectAll(!selectAll);
  };

  const handleSlippageChange = (e) => {
    setSlippage(e.target.value);
  };

  return (
    <Container sx={{ p: 1, display: "flex", justifyContent: "center" }}>
      <Box
        maxWidth="sm"
        width={"100%"}
        sx={{
          bgcolor: "bg.po",
          backdropFilter: "blur(10px)",
          borderRadius: 2,
        }}
      >
        <List dense>
          <ListItem key="nheader" sx={{ py: 1, pb: 2 }}>
            <ListItemAvatar>
              <Box display={"flex"} alignContent={"center"}>
                <Box display={"flex"} alignSelf={"center"} fontSize={16}>
                  <ArrowCircleRightIcon fontSize="inherit" sx={{ pr: 1 }} color="error" />
                </Box>
                <Box fontSize={20} fontWeight={600} color={"error.main"}>
                  Bulk Sell
                </Box>
              </Box>
            </ListItemAvatar>
            <ListItemText primary="" />
            <Box
              color={"text.secondary"}
              sx={{ display: "flex", gap: "8px", alignItems: "center" }}
            >
              <ListItemText primary="select all" />
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
                color="primary"
                size="small"
              />
            </Box>
          </ListItem>
          <Container sx={{ bgcolor: "bg.so", maxHeight: 400, overflow: "auto" }}>
            {tokens.map((token) => (
              <ListItem
                key={token.id}
                disableGutters
                sx={{ borderBottom: "solid 1px", borderColor: "background.paper" }}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{ width: 30, height: 30 }}
                    alt={token.content.metadata.symbol}
                    src={token.content.links.image}
                  />
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <Typography
                      sx={{ display: "flex", flexWrap: "wrap", alignItems: "end", gap: 1 }}
                    >
                      <Typography variant="body" color="text.primary">
                        {token.content.metadata.symbol ? (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none" }}
                            href={`https://solscan.io/account/${token.id}`}
                          >
                            {token.content.metadata.symbol}
                          </a>
                        ) : (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none" }}
                            href={`https://solscan.io/account/${token.id}`}
                          >
                            {token.id.substring(0, 4)}..{token.id.substring(token.id.length - 4)}
                          </a>
                        )}
                      </Typography>
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="caption"
                      color={"text.secondary"}
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <AccountBalanceWallet fontSize="xsmall" />
                      {parseFloat(
                        (
                          token.token_info.balance / Math.pow(10, token.token_info.decimals)
                        ).toFixed(2)
                      )}
                    </Typography>
                  }
                />
                <Box display="flex">
                  <TextField
                    disabled={notTrade.includes(token.id)}
                    type="number"
                    size="small"
                    sx={{ width: 100 }}
                    value={selectedTokens[token.id] / Math.pow(10, token.token_info.decimals) || ""}
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: token.token_info.balance / Math.pow(10, token.token_info.decimals),
                      },
                    }}
                    onChange={(e) =>
                      setSelectedTokens({
                        ...selectedTokens,
                        [token.id]: e.target.value * Math.pow(10, token.token_info.decimals),
                      })
                    }
                  />
                  <Checkbox
                    disabled={notTrade.includes(token.id)}
                    checked={selectedTokens[token.id] > 0}
                    onChange={handleTokenSelect(token.id, token.token_info.balance)}
                    color="primary"
                    size="small"
                  />
                </Box>
              </ListItem>
            ))}
            {tokens.length === 0 && (
              <ListItem
                key="zero"
                disableGutters
                color="white"
                sx={{ borderBottom: "solid 1px", borderColor: "background.paper" }}
              >
                <ListItemText color="text.primary" primary="Nothing to sell" />
              </ListItem>
            )}
          </Container>

          <ListItem key="sol" sx={{ pt: 2 }}>
            <ListItemAvatar>
              <Box display={"flex"} alignContent={"center"}>
                <Box display={"flex"} alignSelf={"center"} fontSize={16}>
                  <ArrowCircleLeftIcon fontSize="inherit" sx={{ pr: 1 }} color="success" />
                </Box>
                <Box fontSize={20} fontWeight={600} color={"success.main"}>
                  Receive
                </Box>
              </Box>
            </ListItemAvatar>
            <ListItemText primary="" />
            <Box display={"flex"} alignItems={"center"} gap={1} color={"text.primary"}>
              {parseFloat(solValue.toFixed(4))}
              <Avatar
                alt="SOL"
                src="https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png"
                sx={{ width: 26, height: 26 }}
              />
              <Typography color={"text.secondary"}>SOL</Typography>
            </Box>
          </ListItem>
        </List>
        <Box
          px={2}
          justifyContent={"right"}
          textAlign={"right"}
          display={"flex"}
          alignItems={"center"}
          gap={0.5}
          color={"text.secondary"}
        >
          <Box fontSize={14}>SLIPPAGE</Box>
          <InputBase
            size="small"
            value={slippage}
            onChange={handleSlippageChange}
            sx={{ width: 20, fontSize: 14, mt: 0.5, color: "text.secondary" }}
            inputProps={{ style: { textAlign: "right" } }}
          />
          <Box fontSize={14}>%</Box>
        </Box>
        <Box pb={2} px={2}>
          <Button
            disabled={selectedTokens.length < 1}
            variant="contained"
            color="green"
            fullWidth
            onClick={handleNuke}
            sx={{ py: 2, borderRadius: 2, fontWeight: 600, color: "black" }}
          >
            SWAP
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
