import { connection } from "../config";

export async function confirmTx(tx) {
  try {
    const latestBlockHash = await connection.getLatestBlockhash();
    console.log(tx);
    const confirm = await connection.confirmTransaction(
      {
        blockhash: latestBlockHash.blockhash,
        lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        signature: tx,
      },
      "confirmed"
    );

    if (confirm.value.err === null) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err.message);
    return false;
  }
}
