/* global BigInt */
import { PublicKey, SystemProgram, TransactionInstruction } from "@solana/web3.js";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { tx } from "../tx";
import toast from "react-hot-toast";

export async function pumpSell(
  mintStr,
  amount,
  slippagePercent,
  publicKey,
  signAllTransactions,
  toastId,
  priorityFee
) {
  slippagePercent = slippagePercent / 100;
  const GLOBAL = new PublicKey("4wTV1YmiEkRvAtNtsSGPtUrqRYQMe5SKy2uB4Jjaxnjf");
  const FEE_RECIPIENT = new PublicKey("CebN5WGQ4jvEPvsVU4EoHEpgzq1VV7AbicfhtW4xC9iM");
  const SYSTEM_PROGRAM = new PublicKey("11111111111111111111111111111111");
  const TOKEN_PROGRAM = new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");
  const ASSOC_TOKEN_ACC_PROG = new PublicKey("ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL");
  const RENT = new PublicKey("SysvarRent111111111111111111111111111111111");
  const PUMP_FUN_ACCOUNT = new PublicKey("Ce6TQqeHC9p8KetsN6JsjHK7UTZk7nasjjnr7XxXp9F1");
  const PUMP_FUN_PROGRAM = new PublicKey("6EF8rrecthR5Dkzon8Nwu78hRvfCKubJ14M5uBEwF6P");
  const LAMPORTS_PER_SOL = 1_000_000_000;
  const UNIT_PRICE = 1_000_000;
  const UNIT_BUDGET = 200_000;

  try {
    // Get coin data
    let coinRes = await fetch(`https://frontend-api.pump.fun/coins/${mintStr}`);

    if (coinRes.error) throw coinRes.error;

    let coinData = await coinRes.json();

    let owner = publicKey;
    let mint = new PublicKey(mintStr);
    let tokenAccount = await getAssociatedTokenAddress(mint, owner);

    // Calculate token account
    let decimal = 6;

    // Calculate price per token in native SOL
    let virtualSolReserves = coinData.virtual_sol_reserves;
    let virtualTokenReserves = coinData.virtual_token_reserves;
    let pricePer = virtualSolReserves / virtualTokenReserves / 1000;

    let minSolOutput = amount * pricePer;
    let slippage = 1 - slippagePercent;
    minSolOutput = parseInt(minSolOutput * slippage * LAMPORTS_PER_SOL);
    amount = parseInt(amount * Math.pow(10, decimal));

    // Build account key list
    let keys = [
      { pubkey: GLOBAL, isWritable: false, isSigner: false },
      { pubkey: FEE_RECIPIENT, isWritable: true, isSigner: false },
      { pubkey: mint, isWritable: false, isSigner: false },
      { pubkey: new PublicKey(coinData.bonding_curve), isWritable: true, isSigner: false },
      {
        pubkey: new PublicKey(coinData.associated_bonding_curve),
        isWritable: true,
        isSigner: false,
      },
      { pubkey: tokenAccount, isWritable: true, isSigner: false },
      { pubkey: owner, isWritable: true, isSigner: true },
      { pubkey: SYSTEM_PROGRAM, isWritable: false, isSigner: false },
      { pubkey: ASSOC_TOKEN_ACC_PROG, isWritable: false, isSigner: false },
      { pubkey: TOKEN_PROGRAM, isWritable: false, isSigner: false },
      { pubkey: PUMP_FUN_ACCOUNT, isWritable: false, isSigner: false },
      { pubkey: PUMP_FUN_PROGRAM, isWritable: false, isSigner: false },
    ];

    // Define integer values
    let sellValue = BigInt("12502976635542562355");
    let integers = [sellValue, BigInt(amount), BigInt(minSolOutput)];

    // Pack integers into binary segments
    let binarySegments = integers.map((integer) => {
      let buffer = Buffer.alloc(8);
      buffer.writeBigUInt64LE(integer);
      return buffer;
    });
    let data = Buffer.concat(binarySegments);

    // Create transaction instructions
    let swapInstruction = new TransactionInstruction({
      data: data,
      keys: keys,
      programId: PUMP_FUN_PROGRAM,
    });
    let instructions = [];
    instructions.push(swapInstruction);

    let fee_wallet = process.env.REACT_APP_FEE_WALLET;
    let fee_percent = process.env.REACT_APP_PUMP_SWAP_FEE_AMOUNT;

    let feeInstruction = SystemProgram.transfer({
      fromPubkey: publicKey,
      toPubkey: new PublicKey(fee_wallet),
      lamports: Math.floor(minSolOutput * fee_percent),
    });

    instructions.push(feeInstruction);

    let result = [];
    let batchSize = 5;

    let res = await tx(
      publicKey,
      signAllTransactions,
      toastId,
      [mintStr],
      instructions,
      result,
      batchSize,
      priorityFee
    );

    if (!res) {
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 10000,
      });
      return false;
    }

    const succ = res.reduce((count, entry) => count + (entry.confirmed ? 1 : 0), 0);

    if (succ > 0) {
      toast.success(
        <div>
          Swap completed.{" "}
          <a target="_blank" rel="noreferrer" href={`https://solscan.io/tx/${res[0].tx}`}>
            View Tx
          </a>
        </div>,
        {
          id: toastId,
          duration: 60000,
        }
      );
      return true;
    } else {
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 60000,
      });
      return false;
    }
  } catch (error) {
    console.log(error.message);
    toast.error(`Transaction failed..`, {
      id: toastId,
      duration: 10000,
    });
    return false;
  }
}
