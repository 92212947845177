import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: "#BBDEFB",
      main: "#2196F3",
      dark: "#1976D2",
      contrastText: "#fff",
    },
    secondary: {
      light: "#F5F5F5",
      main: "#607D8B",
      dark: "#455A64",
      contrastText: "#000",
    },
    background: {
      paper: "#1b1b1b",
    },
    bg: {
      p: "#1b1b1b",
      s: "#101010",
      po: "#ffffff08",
      so: "#ffffff05",
      bord: "#ffffff10",
    },
    green: {
      main: "#1cffbb",
      dark: "#00e29f",
    },
    third: { main: "#fffff2", bg: "#101010" },
    dark: { main: "#424242" },
    text: {
      primary: "#fff",
      secondary: "#78909C",
    },
    divider: "#BDBDBD",
  },
});
