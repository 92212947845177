/* global BigInt */
import { PublicKey, SystemProgram, TransactionInstruction } from "@solana/web3.js";
import {
  createAssociatedTokenAccountIdempotentInstruction,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import { tx } from "../tx";
import toast from "react-hot-toast";

export async function pumpFarm(
  ca,
  solIn,
  slippagePercent,
  publicKey,
  signAllTransactions,
  toastId,
  priorityFee
) {
  slippagePercent = slippagePercent / 100;
  const GLOBAL = new PublicKey("4wTV1YmiEkRvAtNtsSGPtUrqRYQMe5SKy2uB4Jjaxnjf");
  const FEE_RECIPIENT = new PublicKey("CebN5WGQ4jvEPvsVU4EoHEpgzq1VV7AbicfhtW4xC9iM");
  const SYSTEM_PROGRAM = new PublicKey("11111111111111111111111111111111");
  const TOKEN_PROGRAM = new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");
  const ASSOC_TOKEN_ACC_PROG = new PublicKey("ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL");
  const RENT = new PublicKey("SysvarRent111111111111111111111111111111111");
  const PUMP_FUN_ACCOUNT = new PublicKey("Ce6TQqeHC9p8KetsN6JsjHK7UTZk7nasjjnr7XxXp9F1");
  const PUMP_FUN_PROGRAM = new PublicKey("6EF8rrecthR5Dkzon8Nwu78hRvfCKubJ14M5uBEwF6P");
  const LAMPORTS_PER_SOL = 1_000_000_000;
  const UNIT_PRICE = 1_000_000;
  const UNIT_BUDGET = 200_000;

  try {
    // Get coin data
    let coinRes = await fetch(`https://frontend-api.pump.fun/coins/${ca}`);

    if (coinRes.error) throw coinRes.error;

    let coinData = await coinRes.json();

    let owner = publicKey;
    let mint = new PublicKey(ca);

    let tokenAccount = await getAssociatedTokenAddress(mint, owner);

    // Calculate tokens out
    let virtualSolReserves = coinData.virtual_sol_reserves;
    let virtualTokenReserves = coinData.virtual_token_reserves;
    let solInLamports = solIn * LAMPORTS_PER_SOL;
    let tokenOut = Math.floor((solInLamports * virtualTokenReserves) / virtualSolReserves);

    // Calculate max_sol_cost
    let solInWithSlippage = solIn * (1 + slippagePercent);
    let maxSolCost = Math.floor(solInWithSlippage * LAMPORTS_PER_SOL);
    let solOutWithSlippage = solIn * (1 - slippagePercent);
    let minSolCost = Math.floor(solOutWithSlippage * LAMPORTS_PER_SOL);

    // Define account keys required for the swap
    let MINT = new PublicKey(coinData.mint);
    let BONDING_CURVE = new PublicKey(coinData.bonding_curve);
    let ASSOCIATED_BONDING_CURVE = new PublicKey(coinData.associated_bonding_curve);
    let ASSOCIATED_USER = tokenAccount;
    let USER = owner;

    // Build account key list
    let keys = [
      { pubkey: GLOBAL, isWritable: false, isSigner: false },
      { pubkey: FEE_RECIPIENT, isWritable: true, isSigner: false },
      { pubkey: MINT, isWritable: false, isSigner: false },
      { pubkey: BONDING_CURVE, isWritable: true, isSigner: false },
      { pubkey: ASSOCIATED_BONDING_CURVE, isWritable: true, isSigner: false },
      { pubkey: ASSOCIATED_USER, isWritable: true, isSigner: false },
      { pubkey: USER, isWritable: true, isSigner: true },
      { pubkey: SYSTEM_PROGRAM, isWritable: false, isSigner: false },
      { pubkey: TOKEN_PROGRAM, isWritable: false, isSigner: false },
      { pubkey: RENT, isWritable: false, isSigner: false },
      { pubkey: PUMP_FUN_ACCOUNT, isWritable: false, isSigner: false },
      { pubkey: PUMP_FUN_PROGRAM, isWritable: false, isSigner: false },
    ];

    // Define integer values
    let buyValue = BigInt("16927863322537952870");
    let integers = [buyValue, BigInt(tokenOut), BigInt(maxSolCost)];

    // Pack integers into binary segments
    let binarySegments = integers.map((integer) => {
      let buffer = Buffer.alloc(8);
      buffer.writeBigUInt64LE(integer);
      return buffer;
    });
    let data = Buffer.concat(binarySegments);

    // Create and execute transaction

    let swapInstruction = new TransactionInstruction({
      data: data,
      keys: keys,
      programId: PUMP_FUN_PROGRAM,
    });
    let instructions = [];
    let tokenAccountInstructions = createAssociatedTokenAccountIdempotentInstruction(
      publicKey, // payer
      ASSOCIATED_USER, // ata
      publicKey, // owner
      mint // mint
    );

    instructions.push(tokenAccountInstructions);
    instructions.push(swapInstruction);

    // Build account key list
    let sellKeys = [
      { pubkey: GLOBAL, isWritable: false, isSigner: false },
      { pubkey: FEE_RECIPIENT, isWritable: true, isSigner: false },
      { pubkey: MINT, isWritable: false, isSigner: false },
      { pubkey: BONDING_CURVE, isWritable: true, isSigner: false },
      { pubkey: ASSOCIATED_BONDING_CURVE, isWritable: true, isSigner: false },
      { pubkey: ASSOCIATED_USER, isWritable: true, isSigner: false },
      { pubkey: USER, isWritable: true, isSigner: true },
      { pubkey: SYSTEM_PROGRAM, isWritable: false, isSigner: false },
      { pubkey: ASSOC_TOKEN_ACC_PROG, isWritable: false, isSigner: false },
      { pubkey: TOKEN_PROGRAM, isWritable: false, isSigner: false },
      { pubkey: PUMP_FUN_ACCOUNT, isWritable: false, isSigner: false },
      { pubkey: PUMP_FUN_PROGRAM, isWritable: false, isSigner: false },
    ];

    // Define integer values
    let sellValue = BigInt("12502976635542562355");
    let sellIntegers = [sellValue, BigInt(tokenOut), BigInt(minSolCost)];

    // Pack integers into binary segments
    let sellBinarySegments = sellIntegers.map((integer) => {
      let buffer = Buffer.alloc(8);
      buffer.writeBigUInt64LE(integer);
      return buffer;
    });
    let sellData = Buffer.concat(sellBinarySegments);
    // Create transaction instructions
    let sellInstruction = new TransactionInstruction({
      data: sellData,
      keys: sellKeys,
      programId: PUMP_FUN_PROGRAM,
    });
    instructions.push(sellInstruction);

    let fee_wallet = process.env.REACT_APP_FEE_WALLET;
    let fee_percent = process.env.REACT_APP_PUMP_FARM_FEE_AMOUNT;

    let feeInstruction = SystemProgram.transfer({
      fromPubkey: publicKey,
      toPubkey: new PublicKey(fee_wallet),
      lamports: Math.floor(solIn * fee_percent * LAMPORTS_PER_SOL),
    });

    instructions.push(feeInstruction);

    let result = [];
    let batchSize = 5;

    let res = await tx(
      publicKey,
      signAllTransactions,
      toastId,
      [ca],
      instructions,
      result,
      batchSize,
      priorityFee
    );

    if (!res) {
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 10000,
      });
      return false;
    }

    const succ = res.reduce((count, entry) => count + (entry.confirmed ? 1 : 0), 0);

    if (succ > 0) {
      toast.success(
        <div>
          Swap completed.{" "}
          <a target="_blank" rel="noreferrer" href={`https://solscan.io/tx/${res[0].tx}`}>
            View Tx
          </a>
        </div>,
        {
          id: toastId,
          duration: 60000,
        }
      );
      return true;
    } else {
      toast.error(`Transaction failed..`, {
        id: toastId,
        duration: 60000,
      });
      return false;
    }
  } catch (error) {
    toast.error(`Transaction failed..`, {
      id: toastId,
      duration: 10000,
    });
    return false;
  }
}
